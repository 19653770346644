import React from 'react';
import { Paper } from '@mui/material';
import HeroLearnBarter from './HeroLearnBarter';
import IntroLearnBarter from './IntroLearnBarter';
import Earn from './Earn';
import Trade from './Trade';
import NewsBlogs from './NewsBlogs';
import Faqs from './Faqs';
const MainLearnBarter = () => {
  return (
    <Paper elevation={0}>
      <HeroLearnBarter />
      <IntroLearnBarter />
      <Earn />
      <Trade />
      <NewsBlogs />
      <Faqs />
    </Paper>
  );
};

export default MainLearnBarter;
