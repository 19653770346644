import { Paper, Box, Grid, Typography, Card, CardContent } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Fade } from "react-reveal";
import * as Styles from "./Styles.js";
import statebg from "../../../assets/HomePageImages/statebgimg.svg";
import { motion, useAnimation } from "framer-motion";

export default function Diffrance() {
  const controls = useAnimation();

  const statistics = [
    { number: "500+", label: "Happy Businesses" },
    { number: "1000+", label: "Transactions" },
    { number: "100+", label: "Cities" },
    { number: "100000+", label: "Products & Services" },
  ];

  return (
    <Paper
      elevation={0}
      sx={{
        mt: 0,
      }}
    >
      <Box sx={Styles.mainboxdefancebox2}>
        <Typography sx={Styles.MakingAdifferencetypo}>
          Making A Difference
        </Typography>

        <Typography sx={Styles.LetsBartertypo}>
          Think smarter, Let’s Barter !
        </Typography>
      </Box>

      <Box sx={Styles.mainboxmap}>
        <Grid container spacing={4} alignItems="center" sx={{ py: 4, px: 2 }}>
          {/* Left Column - Statistics */}
          <Grid
            item
            xs={12}
            md={6}
            // sx={{
            //   backgroundImage: `url(${statebg})`,
            //   ...Styles.gridfirstdata,
            // }}
          >
            <Grid container spacing={4}>
              {statistics.map((item, index) => (
                <Grid item xs={6} sm={6} key={index}>
                  <Card sx={Styles.carddata}>
                    <CardContent>
                      <Typography sx={Styles.typonumber}>
                        {item.number}
                      </Typography>
                      <Typography sx={Styles.typoame}>{item.label}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Right Column - Map */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: {
                  xl: "80%",
                  lg: "80%",
                  md: "80%",
                  sm: "95%",
                  xs: "95%",
                },
                mx: "auto",
              }}
            >
              <Box
                component="img"
                src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/IndiaMap.png"
                alt="India Map"
                sx={{
                  width: "100%",
                  // maxWidth: "400px",
                  height: "auto",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
