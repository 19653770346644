import axios from 'axios';
import React, { useEffect, useState } from 'react';
import MainRouter from './pages/Router';
import BarterGptLandingPage from './BarterGptLandingPage';
import BXILogo from './assets/image 3.png';

// axios.defaults.baseURL = "http://localhost:7000/";
axios.defaults.baseURL = 'https://bxiback-gp3.unada.in/';

// axios.defaults.baseURL = "https://production-api.unada.in/";
axios.defaults.withCredentials = true;
axios.defaults.headers.bxiapikey = `Bearer K8sY2jF4pL3rQ1hA9gZ6bX7wC5vU0t`;

const Loader = () => {
  return (
    <div
      className="loader"
      style={{
        width: '100vw',
        height: '100vh',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={BXILogo} style={{ width: '300px', height: '300px' }} />
    </div>
  );
};

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (window.self !== window.top) {
    window.top.location = window.self.location;
  }

  return (
    <div className="App">
      {loading ? (
        <Loader />
      ) : (
        <>
          <MainRouter />
          <BarterGptLandingPage />
        </>
      )}
    </div>
  );
}

export default App;
