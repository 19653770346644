import React, { useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import * as Styles from '../Styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import scrollright from '../../../assets/HomePageImages/scrollright.svg';
import scrollLeft from '../../../assets/HomePageImages/scrollLeft.svg';
import bxibiglogo from '../../../assets/HomePageImages/bxibiglogo.svg';

const testimonials = [
  {
    _id: 1,
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/della.mp4',
    Buy: 'Electric Bicycles',
    Sell: 'Adventure park and room night Vouchers',
    text: '',
  },
  {
    _id: 2,
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/karibo.mp4',
    Buy: 'Multiplex Advertisement in Pathaan',
    Sell: 'Karibo Vouchers',
    text: '',
  },
  {
    _id: 3,
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/miraj.mp4',
    Buy: 'Nilons Ketchup',
    Sell: 'Multiplex Advertisements',
    text: '',
  },
  {
    _id: 4,
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/nilon.mp4',

    Buy: 'Multiplex Advertisement Cinepolis, Miraj, Carnival cinema',
    Sell: 'Ketchups in Cinepolis, Miraj, Carnival cinema',
    text: '',
  },
  {
    _id: 5,
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/orchid.mp4',

    Buy: 'Hoardings and radio slots',
    Sell: 'Room Night Voucher',
    text: '',
  },

  {
    _id: 6,
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/skoda.mp4',

    Buy: 'PVR Cinema slides',
    Sell: 'Skoda Cars',
    text: '',
  },
  {
    _id: 7,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/AdonmoReel.png',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/AdonmoReel.mp4',

    Buy: 'Room nights of Orchid Banglore',
    Sell: 'Digital Screens',
    text: '',
  },
  {
    _id: 8,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/stayvista.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/stayvista.mp4',

    Buy: 'Ad Campaign at PVR Cinemas across Delhi & Gurgaon Region',
    Sell: 'Room nights',
    text: 'It was a positive exporience working with Barter Exchange of India due to their professionalism and clear vision, which made our first barter deal smooth.',
  },
  {
    _id: 9,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/timezonevertical.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/timezonevertical.mp4',
    Buy: 'Movie vouchers, Emotorad bicycles, Kids product',
    Sell: 'Timezone vouchers',
    text: "We had a great experience with BXI's smooth process and hope for their continued growth. We look forward to our future association.",
  },
  {
    _id: 10,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/zephyrvertical.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/zephyrvertical.mp4',
    Buy: 'Della lodging, boarding program, hall, banquets',
    Sell: 'Zephyr Toys',
    text: 'The concept of barter was a fantastic idea, it is win win situation for all of us',
  },
  {
    _id: 11,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/AjmalPerfumes.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/AjmalPerfumes.mp4',
    Buy: 'Multiplex Advertisements',
    Sell: 'Perfumes Vouchers',
    text: 'Doing barter with BXI it was amazing experience we did the first time and we enjoyed doing it twice.',
  },
  {
    _id: 12,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/LIMELIGHT.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/LIMELIGHT.mp4',
    Buy: 'Multiplex Advertisements',
    Sell: 'Gift Vouchers of Exquisite Range Jewellery',
    text: 'The platform provided a seamless and efficient experience for exploring media options through barter. The organization of brands was well-structured.',
  },
  {
    _id: 13,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/strategicalliances.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/strategicalliances.mp4',
    Buy: 'Ad Campaign at Inox, PVR and Miraj Cinemas in 160+ Screens',
    Sell: 'Electric Cycles',
    text: 'The concept of barter on BXI is intriguing, and interesting and we anticipate future collaborations',
  },
  {
    _id: 14,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/part1DURGESHGUPTAA.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/part1DURGESHGUPTAA.mp4',
    Buy: 'Metro local train branding,newspaper ads, mall kiosk',
    Sell: 'Electric Scooters',
    text: 'Our doubt for the product was resolved once talked to viral sir and we had a very good experience and feel',
  },
  {
    _id: 15,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/part2DURGESHGUPTAA.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/part2DURGESHGUPTAA.mp4',
    Buy: 'Metro local train branding,newspaper ads, mall kiosk',
    Sell: 'Electric Scooters',
    text: 'Our doubt for the product was resolved once talked to viral sir and we had a very good experience and feel',
  },
  {
    _id: 16,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/EMotorad.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/EMotorad.mp4',
    Buy: 'Multimedia Advertisements',
    Sell: 'Electric Cycles',
    text: 'Made Wonders for us.',
  },
  {
    _id: 17,
    poster_url:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/whisperingwoods.jpg',
    image:
      'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/whisperingwoods.mp4',
    Buy: 'Housekeeping Products',
    Sell: 'Entire Resort Staycation on Barter',
    text: "My experience with Barter Exchange of India has been excellent. They've always been responsive and helpful, even at late hours. I appreciate their transparency and the quality of the products they provide. I look forward to a long-lasting partnership",
  },
];

const ChooseUs = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (_, next) => setActiveSlide(next),
    nextArrow: <CustomArrow direction="right" />,
    prevArrow: <CustomArrow direction="left" />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Paper elevation={0} sx={{ height: '100vh', mt: 0, overflow: 'hidden' }}>
      <Box sx={{ position: 'relative', height: '100%' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            right: '0',
            transform: 'translateY(-50%)',
            zIndex: 0,
            display: {
              xl: 'block',
              lg: 'block',
              md: 'none',
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          <img
            src={bxibiglogo}
            alt="Big Logo"
            style={{
              width: 'auto',
              height: '40%',
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography sx={{ ...Styles.headMainText, textAlign: 'center' }}>
            Why{' '}
            <span style={{ color: 'rgba(108, 108, 162, 1)' }}>Choose Us?</span>
          </Typography>
          <Typography sx={Styles.smallChooseText}>
            Hear it from our clients!
          </Typography>
        </Box>
        <Box sx={Styles.cardTextBoxAbove}>
          <Box sx={Styles.cardTextBox}>
            <Typography sx={Styles.slidecolorText}>
              {testimonials[activeSlide]?.text}
            </Typography>
            <Box mt={2}>
              <Typography sx={Styles.slideSubText2}>
                What did you Buy?
              </Typography>
              <Typography sx={Styles.slideSubText}>
                {testimonials[activeSlide]?.Buy}
              </Typography>
              <Box mt={2}>
                <Typography sx={Styles.slideSubText2}>
                  What did you Sell?
                </Typography>
                <Typography sx={Styles.slideSubText}>
                  {testimonials[activeSlide]?.Sell}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: '80%', mx: 'auto', position: 'relative' }}>
            <Slider {...settings}>
              {testimonials.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    padding: '10px',
                    textAlign: 'center',
                    transform:
                      index === activeSlide ? 'scale(1.02)' : 'scale(0.85)',
                    transition: 'all 0.3s ease',
                  }}
                >
                  <Box
                    component={'video'}
                    poster={item.poster_url ? item.poster_url : ''}
                    autoPlay={index === activeSlide}
                    loop
                    controls
                    muted
                    playsInline
                    src={item.image}
                    alt={`Client ${index + 1}`}
                    sx={{
                      width: '100%',
                      height:
                        index === activeSlide
                          ? {
                              xl: '420px',
                              lg: '420px',
                              md: '420px',
                              sm: '300px',
                              xs: '300px',
                            }
                          : '350px',
                      objectFit: 'fill',
                      borderRadius: {
                        xl: '10px',
                        lg: '10px',
                        md: '10px',
                        sm: '2px',
                        xs: '2px',
                      },
                    }}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ChooseUs;

const CustomArrow = ({ onClick, direction }) => {
  const arrowImage = direction === 'left' ? scrollLeft : scrollright;
  return (
    <Box
      onClick={onClick}
      sx={{
        position: 'absolute',
        bottom: { xl: '30px', lg: '30px', md: '30px', sm: '10px', xs: '10px' },
        left:
          direction === 'left'
            ? { xl: '60%', lg: '60%', md: '60%', sm: '35%', xs: '35%' }
            : { xl: '75%', lg: '75%', md: '75%', sm: '65%', xs: '65%' },
        transform: 'translateX(-50%)',
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        zIndex: 10,
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
      }}
    >
      <img src={arrowImage} alt={`${direction} arrow`} />
    </Box>
  );
};
