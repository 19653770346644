import React from 'react';
import { Paper, Box } from '@mui/material';
import Data from './Data';
import Diffrance from './Diffrance';
import Brand from './Brand';
import ChooseUs from './ChooseUs';

const MainHome = () => {
  return (
    <Paper elevation={0}>
      <Box
        sx={{
          width: '100%',
          maxHeight: '80%',
          mt: 2,
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            // minWidth: "100vw",
            height: 'auto',
            // minHeight: "80vh",
            // maxHeight: "80vh",
            objectFit: 'cover',
          }}
        >
          <source
            src={`https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/bannerVideo.mp4`}
            type="video/mp4"
          />
        </video>
      </Box>

      <Data />
      <ChooseUs />
      <Diffrance />
      <Brand />
    </Paper>
  );
};

export default MainHome;
