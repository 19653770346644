import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";

const BulkUploadExcelpreviewPage = () => {
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const location = useLocation();
  const FileLink = location?.state?.Link;

  const handleFetchExcel = async () => {
    if (!FileLink) {
      setErrorMessage("Please provide a valid Excel file URL!");
      return;
    }

    try {
      const response = await fetch(FileLink);

      if (!response.ok) {
        throw new Error("Failed to download Excel file.");
      }

      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), {
        type: "array",
      });

      // Process the first sheet
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData.length < 2) {
        setErrorMessage(
          "Excel file must contain headers and at least one row of data!"
        );
        return;
      }

      // Generate columns and rows
      const dynamicColumns = jsonData[0].map((header, index) => ({
        key: `col${index}`,
        header: header || `Column ${index + 1}`,
      }));

      const newRows = jsonData.slice(1).map((row, rowIndex) => {
        const rowData = { key: rowIndex };
        row.forEach((cell, cellIndex) => {
          rowData[`col${cellIndex}`] = cell?.toString() || "";
        });
        return rowData;
      });

      setCols(dynamicColumns);
      setRows(newRows);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage("Error fetching or parsing Excel file: " + error.message);
    }
  };

  useEffect(() => {
    handleFetchExcel();
  }, [FileLink]);

  return (
    <div style={{ padding: 24 }}>
      <Typography
        variant="h4"
        gutterBottom
        style={{ fontWeight: 600, fontSize: "1.8rem" }}
      >
        Bulk Upload Excel Preview
      </Typography>

      {errorMessage && (
        <Alert severity="error" style={{ marginBottom: 16 }}>
          {errorMessage}
        </Alert>
      )}

      {rows.length > 0 && (
        <TableContainer component={Paper} style={{ marginTop: 16 }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  height: "24px",
                  borderRadius: "5px",
                  bgcolor: "#c64091",
                }}
              >
                <TableCell
                  style={{
                    p: 0.3,
                    border: "2px solid #CDCDCD",
                    width: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      ...TableBottomtext,
                      fontWeight: 600,
                      textAlign: "left",
                      minWidth: "50px",
                      color: "white",
                    }}
                  >
                    No .
                  </Typography>
                </TableCell>
                {cols.map((col) => (
                  <TableCell
                    key={col.key}
                    style={{
                      p: 0.3,
                      border: "2px solid #CDCDCD",
                      width: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        ...TableBottomtext,
                        fontWeight: 600,
                        textAlign: "left",
                        minWidth: "250px",
                        color: "white",
                      }}
                    >
                      {col.header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => (
                <TableRow
                  key={row.key}
                  sx={{
                    height: "24px",
                  }}
                >
                  <TableCell
                    style={{
                      p: 0.3,
                      border: "2px solid #CDCDCD",
                      bgcolor: "#2261A2",
                      width: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        ...TableBottomtext,
                        fontWeight: 600,
                        textAlign: "left",
                        minWidth: "50px",
                      }}
                    >
                      {idx + 1}
                    </Typography>
                  </TableCell>
                  {cols.map((col) => (
                    <TableCell
                      key={`${row.key}-${col.key}`}
                      sx={{
                        p: 0,
                        border: "1px solid #CDCDCD",
                        borderLeft: "2px solid #CDCDCD",
                        width: "auto",
                      }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          textAlign: "left",
                          minWidth: "250px",
                        }}
                      >
                        {row[col.key]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default BulkUploadExcelpreviewPage;

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#525252",
  opacity: 1,
};
