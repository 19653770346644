import React from 'react';
import { Box, Grid, Typography, Button, Paper } from '@mui/material';
import * as Styles from '../Styles';
import Benfits from './Benfits';
import Trust from './Trust';
import PricingDetails from '../../../pages/Register/PricingDetails';
import textTileicon from '../../../assets/marketplaceicons/commonimg/textile.svg';
import lifestyle from '../../../assets/marketplaceicons/commonimg/lifestyle.svg';
import media from '../../../assets/marketplaceicons/commonimg/media.svg';
import office from '../../../assets/marketplaceicons/commonimg/officesupply.svg';
import electronics from '../../../assets/marketplaceicons/commonimg/electronics.svg';
import hotel from '../../../assets/marketplaceicons/commonimg/hotel.svg';
import qsr from '../../../assets/marketplaceicons/commonimg/qsr.svg';
import airline from '../../../assets/marketplaceicons/commonimg/airline.svg';
import entertenment from '../../../assets/marketplaceicons/commonimg/entertenment.svg';
import fmcg from '../../../assets/marketplaceicons/commonimg/fmcg.svg';
import mobility from '../../../assets/marketplaceicons/commonimg/mobility.svg';
import other from '../../../assets/marketplaceicons/commonimg/other.svg';
const NewMarketplace = () => {
  const categories = [
    {
      name: 'Textile',
      image: textTileicon,
    },
    {
      name: 'Lifestyle',
      image: lifestyle,
    },
    {
      name: 'Media',
      image: media,
    },
    {
      name: 'Office supply',
      image: office,
    },
    {
      name: 'Electronics',
      image: electronics,
    },
    {
      name: 'Hotel',
      image: hotel,
    },
    {
      name: 'QSR',
      image: qsr,
    },
  ];
  const categories2 = [
    {
      name: 'Airline',
      image: airline,
    },
    {
      name: 'Entertainment & Events',
      image: entertenment,
    },
    {
      name: 'FMCG',
      image: fmcg,
    },
    {
      name: 'Mobility',
      image: mobility,
    },
    {
      name: 'Other',
      image: other,
    },
  ];
  return (
    <Paper elevation={0} sx={{ mt: 10 }}>
      <Box sx={{ width: '90%', mx: 'auto' }}>
        <Typography sx={Styles.smallHeadText}>ACTIVE Categories</Typography>
        <Typography sx={Styles.headText}>
          Explore Inventory Available on the{' '}
          <span style={{ color: ' rgba(108, 108, 162, 1)' }}> Marketplace</span>
        </Typography>
      </Box>
      {/*  */}
      <Box
        sx={{
          width: '100%',
          background: 'rgba(108, 108, 162, 0.09)',
          p: { xl: 4, lg: 4, md: 4, sm: 0.5, xs: 0.5 },
          mt: 2,
          height: 'auto',
        }}
      >
        <Box
          sx={{
            width: '90%',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-around',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          {categories?.map((item, index) => (
            <Box sx={Styles.insideCategoryBox}>
              <Box
                component={'img'}
                src={item?.image}
                sx={{ maxWidth: '40px', height: 'auto' }}
              />
              <Typography sx={Styles.categoryText}>{item?.name}</Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: { xl: '70%', lg: '70%', md: '70%', sm: '90%', xs: '90%' },
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-around',
            gap: 1,
            flexWrap: 'wrap',
            mt: { xl: 3, lg: 3, md: 3, sm: 1, xs: 1 },
          }}
        >
          {categories2?.map((item, index) => (
            <Box sx={Styles.insideCategoryBox}>
              <Box
                component={'img'}
                src={item?.image}
                sx={{ maxWidth: '40px', height: 'auto' }}
              />
              <Typography sx={Styles.categoryText}>{item?.name}</Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: { xl: 4, lg: 4, md: 4, sm: 2, xs: 2 },
            alignItems: 'center',
          }}
        >
          <Box sx={Styles.ExlporeBtn}>Explore Marketplace</Box>
        </Box>
      </Box>
      {/*  */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 5,
          mt: 4,
        }}
      >
        <Typography
          sx={{ ...Styles.headText, textAlign: 'center', width: '100%' }}
        >
          How to Buy & Sell
        </Typography>
        <Box
          component={'img'}
          src={
            'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Group+1000003362.svg'
          }
          sx={{
            width: { xl: '70%', lg: '70%', md: '70%', sm: '90%', xs: '90%' },
            height: 'auto',
            mx: 'auto',
          }}
        />
      </Box>
      <Benfits />
      <Trust />
      <PricingDetails />
    </Paper>
  );
};

export default NewMarketplace;
