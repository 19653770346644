import React, { useRef, useState } from "react";
import {
  Box,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import BxiCoin from "../../assets/Images/CommonImages/BXIToken.svg";
import CommaSeprator from "../../components/CommaSeprator";
import { toast } from "react-toastify";
import axios from "axios";
import radiouncheck from "../../assets/Dashboard/radiouncheck.svg";
import radiocheck from "../../assets/Dashboard/radiocheck.svg";
import { useGetAllCompanyProducts } from "../../views/Dashboard/useGetAllCompanyProducts.js";
import PrivateDealAgreement from "../../components/PrivateDealAgrrement.jsx";

const VoucherOrderSummary = ({ VoucherSummaryData, CompanyData, TDS }) => {
  const componentRef = useRef();
  const [Codechecked, setCodeChecked] = useState(false);
  const [CouponCodeData, setCouponCodeData] = useState();
  const [CouponCode, setCouponCode] = useState();
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [ProductId, setProductId] = useState();
  const [ProductData, setProductData] = useState();

  const handleCouponCheckChange = (event) => {
    setCodeChecked(event.target.checked);
  };

  const itemDetails = [
    {
      item: "Seller Name :",
      value:
        VoucherSummaryData?.PurchaseOrderData?.SellerDetails?.SellerCompanyName,
    },
    {
      item: "Address :",
      value:
        VoucherSummaryData?.PurchaseOrderData?.SellerDetails?.Address
          ?.AddressLine,
    },
    {
      item: "GSTIN :",
      value: VoucherSummaryData?.PurchaseOrderData?.SellerDetails?.GSTIN,
    },
    {
      item: "State :",
      value:
        VoucherSummaryData?.PurchaseOrderData?.SellerDetails?.Address?.State,
    },
  ];
  const pror = [
    {
      item: "Order Date :",
      value: new Date(VoucherSummaryData?.PoDate).toLocaleDateString("en-IN"),
    },
  ];

  let TotalAdditionalCostWtihoutGSTInBXI = 0;
  let totaladditionalcostGstinrupee = 0;
  let totaladditionalcostGstinbxi = 0;
  let TotalPriceWithoutGST = 0;
  let GrandTotal = 0;

  VoucherSummaryData?.PurchaseOrderData?.ProductData?.map((el, idx) => {
    TotalAdditionalCostWtihoutGSTInBXI +=
      el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;

    if (
      el &&
      (el?.AdditionCost?.AdditionCostArray?.length > 0 ||
        el?.AdditionCost?.AdditionCostArrayData?.length > 0 ||
        el?.AdditionalCost?.AdditionCostArrayData?.length > 0)
    ) {
      // Choose the correct array to iterate based on availability
      const additionalCostArray = el?.AdditionalCost?.AdditionCostArrayData;

      // Iterate over the array
      additionalCostArray.forEach((item, index) => {
        // Check currency type and calculate total additional cost accordingly
        if (item.currencyType === "₹") {
          totaladditionalcostGstinrupee +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        } else if (item.currencyType === "BXITokens") {
          totaladditionalcostGstinbxi +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        }
      });
    }

    TotalPriceWithoutGST += el?.TotalPriceWithoutGST;

    GrandTotal +=
      el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI +
      el?.TotalPriceWithoutGST
        ? el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI +
          el?.TotalPriceWithoutGST
        : el?.TotalPriceInBXI;
  });

  let totalTaxAmount =
    VoucherSummaryData?.INRDetails?.totalGstAmount +
    VoucherSummaryData?.INRDetails?.totalGstInrOfAdCost +
    VoucherSummaryData?.INRDetails?.bxiCommisionGST;

  const calculateSums = (productVariations) => {
    let totalValueSum = 0;
    let maxOrderQtySum = 0;

    productVariations?.forEach((item) => {
      const currentValue = item?.MaxOrderQuantity * item?.DiscountedPrice;

      totalValueSum += currentValue;
      maxOrderQtySum += item?.MaxOrderQuantity;
    });

    return {
      totalValueSum,
      maxOrderQtySum,
    };
  };

  async function UpdateCouponAgreements() {
    try {
      const response = await axios.put(
        `/private_deal/update_agreements/${CouponCodeData?._id}`,
        {
          Order_Id: VoucherSummaryData?._id,
          ReadyStock_Product: ProductData,
          Order_Number: VoucherSummaryData?.OrderId,
        }
      );
    } catch (error) {}
  }

  async function UpdateInrInOrder() {
    try {
      const response = await axios.put(
        "voucher/voucher_order_inr_update",
        {
          OrderId: VoucherSummaryData?._id,
        },
        {
          withCredentials: true,
        }
      );

      if (response.data) {
        window.location.reload();
        toast.success("Order Updated", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {}
  }

  const UpdateOrderWithOrder = async (data) => {
    await axios
      .put(`order/update_order_with_any_data/${VoucherSummaryData?._id}`, {
        CouponData: data,
      })
      .then((res) => {
        if (res) {
          UpdateInrInOrder();
          if (ProductData) {
            UpdateCouponAgreements();
          }
        }
      })
      .catch((err) => {});
  };

  async function UpdateProductReadyStock() {
    try {
      const response = await axios.put("product/product_ready_stock_update", {
        id: ProductId,
      });
    } catch (error) {}
  }

  async function UpdateInstantBalance(CouponCode) {
    if (!CouponCode) {
      toast.error("Please enter a coupon code", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      try {
        const response = await axios.put("wallet/update_instant_balance", {
          OrderId: VoucherSummaryData?._id,
          CouponId: CouponCode?._id,
          balance: Number(CouponCode?.TokenAmount),
          OnetoMany: CouponCode?.OnetoMany,
        });
      } catch (error) {}
    }
  }

  const { data: CompaniesProducts, refetch: refetch } =
    useGetAllCompanyProducts();

  const GetAllCoupons = async () => {
    if (!CouponCode) {
      toast.error("Please enter a coupon code", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      try {
        const response = await axios.post("private_deal/validate_coupon", {
          SellerCompanyId: VoucherSummaryData?.SellerCompanyId,
          BuyercompanyId: VoucherSummaryData?.BuyerCompanyId,
          CouponCode: CouponCode,
          Token: TotalAdditionalCostWtihoutGSTInBXI + TotalPriceWithoutGST,
        });

        if (response?.data?.message?.includes("is Valid")) {
          if (response?.data?.data?.at(0)?.OnetoMany) {
            setOpen(true);
          } else {
            UpdateOrderWithOrder(response?.data?.data?.at(0));
            UpdateInstantBalance(response?.data?.data?.at(0));
          }
        }
        response?.data?.message?.includes("is Valid")
          ? toast.success(response?.data?.message, {
              position: "top-center",
              autoClose: 2000,
            })
          : toast.error(response?.data?.message, {
              position: "top-center",
              autoClose: 2000,
            });
        setCouponCodeData(response?.data?.data?.at(0));
      } catch (error) {}
    }
  };

  return (
    <Paper
      sx={{
        bgcolor: "#fff",
        boxShadow: "none",
        borderRadius: "20px",
        height: "auto",
        minHeight: "520px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
      ref={componentRef}
    >
      <Box ref={componentRef} sx={{ width: "100%" }}>
        <Grid
          container
          gap={8}
          sx={{
            py: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xl={5.5}
            lg={5.5}
            md={5.5}
            sm={12}
            xs={12}
            sx={{
              ...gridstyle,
              width: "830px",
              height: "auto",
              position: "relative",
            }}
          >
            <Box sx={mainbox}>
              <Typography sx={headbox}>
                {" "}
                &nbsp;&nbsp;&nbsp;Supplier Details
              </Typography>
              <Typography
                sx={{
                  ...headbox,
                  fontSize: "14px",
                  float: "right",
                  textAlign: "right",
                  mr: "10px",
                }}
              >
                Order: {VoucherSummaryData?.OrderId}
              </Typography>
            </Box>
            <Box sx={contentbox}>
              {itemDetails?.map((el, idx) => {
                return (
                  <Box sx={mapbox} key={idx}>
                    <Typography sx={elitem}>{el.item}</Typography>
                    <Typography
                      sx={{
                        ...elvalue,
                        width: "85%",
                        textAlign: "left",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {el.value}
                    </Typography>
                  </Box>
                );
              })}

              <Box sx={{ mt: "20px", width: "90%" }}>
                {pror?.map((el, idx) => {
                  return (
                    <Box
                      sx={{
                        ...mapbox,
                        width: "auto",
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "30px",
                      }}
                      key={idx}
                    >
                      <Typography
                        sx={{
                          ...elitem,
                          textAlign: "left",
                        }}
                      >
                        {el.item}
                      </Typography>
                      <Typography
                        sx={{
                          ...elvalue,
                          width: "auto",
                          textAlign: "left",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {el.value}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Typography
                sx={{
                  ...selername,
                  justifyContent: "flex-end",
                  position: "absolute",
                  mt: 8,
                }}
              >
                Code :{" "}
                {
                  VoucherSummaryData?.PurchaseOrderData?.SellerDetails
                    ?.StateCode
                }
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xl={5.5}
            lg={5.5}
            md={5.5}
            sm={12}
            xs={12}
            sx={{
              ...gridstyle,
              width: "399px",
              height: "100%",
              minHeight: "380px",
            }}
          >
            <Box sx={headbox2}>
              <Typography sx={detailtext}>Coins + INR Details</Typography>
              <Typography sx={detailtext2}>Details with more info</Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                py: 2,
              }}
            >
              <Table sx={{ maxWidth: "85%" }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Particulars
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        Basic Value
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        GST (INR)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        Total (Coins+INR)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Product Cost */}
                  {VoucherSummaryData?.INRDetails?.totalGstAmount !== 0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          Product Cost (Coins){" "}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              TotalPriceWithoutGST
                                ? TotalPriceWithoutGST
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.totalGstAmount
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.totalGstAmount
                                ? VoucherSummaryData?.INRDetails
                                    ?.totalGstAmount + TotalPriceWithoutGST
                                : 0.0
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {/* Additional Cost (INR)*/}
                  {/* {VoucherSummaryData?.INRDetails?.totalGstInrOfAdCost +
                    VoucherSummaryData?.INRDetails?.totalValueInrOfAdCost !==
                    0 && ( */}
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderLeft: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "left" }}
                      >
                        Additional Cost (INR)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        {" "}
                        <CommaSeprator
                          Price={
                            VoucherSummaryData?.INRDetails
                              ?.totalValueInrOfAdCost
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            totaladditionalcostGstinrupee
                              ? totaladditionalcostGstinrupee
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        {VoucherSummaryData?.INRDetails
                          ?.totalValueInrOfAdCost ? (
                          <>
                            {VoucherSummaryData?.INRDetails
                              ?.totalValueInrOfAdCost !== 0 && (
                              <CommaSeprator
                                Price={
                                  VoucherSummaryData?.INRDetails
                                    ?.totalValueInrOfAdCost +
                                  totaladditionalcostGstinrupee
                                }
                              />
                            )}
                          </>
                        ) : VoucherSummaryData?.POTotals
                            ?.TotalAdditionalCostInRupee ? (
                          <>
                            {VoucherSummaryData?.POTotals
                              ?.TotalAdditionalCostInRupee !== 0 && (
                              <CommaSeprator
                                Price={
                                  VoucherSummaryData?.POTotals
                                    ?.TotalAdditionalCostInRupee +
                                  totaladditionalcostGstinrupee
                                }
                              />
                            )}
                          </>
                        ) : (
                          "0.00"
                        )}
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* )} */}

                  {/* Additional Cost (Coins)*/}
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderLeft: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "left" }}
                      >
                        Additional Cost (Coins)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            TotalAdditionalCostWtihoutGSTInBXI
                              ? TotalAdditionalCostWtihoutGSTInBXI
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            totaladditionalcostGstinbxi
                              ? totaladditionalcostGstinbxi
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            TotalAdditionalCostWtihoutGSTInBXI +
                            totaladditionalcostGstinbxi
                              ? TotalAdditionalCostWtihoutGSTInBXI +
                                totaladditionalcostGstinbxi
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* BXI Commission */}
                  {VoucherSummaryData?.INRDetails?.totalBxiCommission !== 0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          BXI Commission
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.totalBxiCommission
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.bxiCommisionGST
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.totalBxiCommission
                                ? VoucherSummaryData?.INRDetails
                                    ?.totalBxiCommission +
                                  VoucherSummaryData?.INRDetails
                                    ?.bxiCommisionGST
                                : 0.0
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={
                            VoucherSummaryData?.INRDetails?.WithoutGSTValue
                              ? VoucherSummaryData?.INRDetails?.WithoutGSTValue
                              : 0.0
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={totalTaxAmount ? totalTaxAmount : 0.0}
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={
                            VoucherSummaryData?.INRDetails?.WithoutGSTValue +
                            totalTaxAmount
                              ? VoucherSummaryData?.INRDetails
                                  ?.WithoutGSTValue + totalTaxAmount
                              : 0.0
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <br />
              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Table sx={{ maxWidth: "57%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          bgcolor: "#f3f6f9",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          TDS @{TDS}%
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                        }}
                        align="right"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={
                              VoucherSummaryData?.INRDetails?.TDS
                                ? VoucherSummaryData?.INRDetails?.TDS
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
              <br />
              <br />
              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Table sx={{ maxWidth: "71%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          bgcolor: "#f3f6f9",
                        }}
                        align="center"
                        colSpan={3}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Payment Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Buyer To Seller
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={
                              TotalAdditionalCostWtihoutGSTInBXI +
                              TotalPriceWithoutGST -
                              VoucherSummaryData?.INRDetails?.TDS
                                ? TotalAdditionalCostWtihoutGSTInBXI +
                                  TotalPriceWithoutGST -
                                  VoucherSummaryData?.INRDetails?.TDS
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Coins
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Buyer To Seller
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          {VoucherSummaryData?.BuyerChoosedTransportation
                            ?.TransportationType === "notransportation" ||
                          VoucherSummaryData?.IsMedia ||
                          VoucherSummaryData?.IsVoucher ? (
                            <CommaSeprator
                              Price={
                                VoucherSummaryData?.INRDetails?.TotalInrToPay
                                  ? VoucherSummaryData?.INRDetails
                                      ?.TotalInrToPay
                                  : 0.0
                              }
                            />
                          ) : (
                            <>
                              {VoucherSummaryData?.INRDetails?.packagingCost ||
                              VoucherSummaryData?.INRDetails?.InsuranceCost ? (
                                <CommaSeprator
                                  Price={
                                    VoucherSummaryData?.INRDetails
                                      ?.TotalInrToPay
                                      ? VoucherSummaryData?.INRDetails
                                          ?.TotalInrToPay
                                      : 0.0
                                  }
                                />
                              ) : (
                                <CommaSeprator
                                  Price={
                                    VoucherSummaryData?.INRDetails
                                      ?.TotalInrToPay
                                      ? VoucherSummaryData?.INRDetails
                                          ?.TotalInrToPay
                                      : 0.0
                                  }
                                />
                              )}
                            </>
                          )}
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Inr
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Seller to BXI
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          {VoucherSummaryData?.IsMedia ? (
                            <CommaSeprator
                              Price={
                                VoucherSummaryData?.INRDetails
                                  ?.TotalBxiCommission
                                  ? VoucherSummaryData?.INRDetails
                                      ?.TotalBxiCommission +
                                    VoucherSummaryData?.INRDetails
                                      ?.BxiCommisionGST +
                                    VoucherSummaryData?.INRDetails?.TDS
                                  : 0.0
                              }
                            />
                          ) : (
                            <CommaSeprator
                              Price={
                                VoucherSummaryData?.INRDetails
                                  ?.totalBxiCommission
                                  ? VoucherSummaryData?.INRDetails
                                      ?.totalBxiCommission +
                                    VoucherSummaryData?.INRDetails
                                      ?.bxiCommisionGST +
                                    VoucherSummaryData?.INRDetails?.TDS
                                  : 0.0
                              }
                            />
                          )}
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Inr
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
            </Box>
          </Grid>
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "96%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  ...elitem,
                  width: "80%",
                  textAlign: "left",
                }}
              >
                <b>NOTE :-</b>
                <br />
                BXI Commission is always charged on Barter Coins.
                <br />
                {TDS}% TDS is collected in INR on Total Gross Value.
                <br />
              </Typography>
            </Box>
          </Box> */}
        </Grid>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 1,
            // bgcolor: "red",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {VoucherSummaryData?.PurchaseOrderData?.CouponData ? (
              <Box sx={{ width: "95%", mx: "auto" }}>
                <label
                  style={{
                    ...AddressInputTextStyle,
                    fontSize: "18px",
                    lineHeight: "15px",
                  }}
                >
                  Already Coupon Applied
                </label>
              </Box>
            ) : (
              <>
                <Box sx={{ width: "95%", mx: "auto" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Codechecked}
                        onChange={handleCouponCheckChange}
                        name="checked"
                        color="primary"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            width: "24px",
                            height: "24px",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "12px", color: "#6B7A99" }}
                      >
                        Click here to Apply Coupon
                      </Typography>
                    }
                  />
                </Box>

                {Codechecked ? (
                  <>
                    {!CouponCodeData?._id ? (
                      <Box
                        sx={{
                          width: "95%",
                          mx: "auto",
                          alignItems: "center",
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "5px",
                          mt: 1,
                        }}
                      >
                        <label style={AddressInputTextStyle}>
                          Apply Coupon Code :
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setCouponCode(e.target.value)}
                          required
                          style={{
                            ...AddressInputStyle,
                            width: "120px",
                            height: "25px",
                            border: "0.5px solid black",
                          }}
                        />

                        <Button
                          onClick={() => GetAllCoupons()}
                          variant="contained"
                          sx={{
                            width: "auto",
                            textTransform: "none",
                            height: "25px",
                            bgcolor: "#2261A2",
                            borderRadius: "6px",
                          }}
                        >
                          Apply Coupon Code
                        </Button>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "95%",
                          mx: "auto",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "5px",
                          mt: 1,
                        }}
                      >
                        <label
                          style={{
                            ...AddressInputTextStyle,
                            fontSize: "18px",
                            lineHeight: "5px",
                          }}
                        >
                          Coupon Applied
                        </label>
                      </Box>
                    )}
                  </>
                ) : null}
              </>
            )}
            <Dialog
              open={open}
              fullWidth
              maxWidth="lg"
              sx={{
                backdropFilter: "blur(2px)",
              }}
              PaperProps={{
                sx: {
                  width: "60%",
                  maxHeight: 600,
                  borderRadius: "20px",
                },
              }}
            >
              <DialogTitle id="responsive-dialog-title">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                      mx: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "18px",
                        lineHeight: "28px",
                        color: "#6B7A99",
                        textAlign: "center",
                      }}
                    >
                      Please select the product for which you wish to put in
                      Ready Stock
                    </Typography>
                  </Box>
                </Box>
              </DialogTitle>

              <DialogContent
                sx={{
                  overflow: "auto",
                  mr: 2,
                  "::-webkit-scrollbar": {
                    display: "flex",
                  },
                  "::-webkit-scrollbar-thumb": {
                    dynamic: "#8d8e90",
                    minHeight: "10px",
                    borderRadius: "3px",
                  },
                  "::-webkit-scrollbar-thumb:vertical": {
                    miaxHeight: "10px",
                  },
                }}
              >
                {CompaniesProducts && CompaniesProducts.length > 0 ? (
                  CompaniesProducts.slice()
                    .reverse()
                    // .filter((el) => el.IsReadyStock === false)
                    .map((el, idx) => {
                      const sums = calculateSums(el?.ProductsVariantions);
                      return (
                        <Box key={idx}>
                          <Box
                            sx={{
                              width: "100%",
                              height: "80px",
                              maxHeight: "122px",
                              background: "#FFFFFF",
                              border: "1px solid #EDEFF2",
                              borderRadius: "10px 10px 10px 10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              alignContent: "center",
                              mt: 2,
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: {
                                  xl: "800px",
                                  lg: "800px",
                                  md: "800px",
                                  sm: "350px",
                                  xs: "350px",
                                },
                                height: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                alignContent: "center",
                                gap: "2rem",
                              }}
                            >
                              <Box
                                sx={{
                                  ml: "1%",
                                  height: "80px",
                                  width: "150px",
                                  maxHeight: "122px",
                                  minWidth: "150px",
                                  maxWidth: "150px",
                                  borderRadius: "25px 25px 25px 25px",
                                  backgroundImage:
                                    el?.ListingType === "Voucher"
                                      ? `url(${el?.VoucherImages?.at(0)?.url})`
                                      : `url(${el?.ProductImages?.at(0)?.url})`,
                                  backgroundSize: "contain",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "start",
                                  alignContent: "start",
                                  flexDirection: "column",
                                  gap: "0.5rem",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "50%",
                                    // mx: "auto",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...ProductNameTextStyle,
                                      display: "-webkit-box",
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {el?.ProductName}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    width: "50%",
                                    // mx: "auto",
                                  }}
                                >
                                  <Typography sx={ProductMetaTextStyle}>
                                    {el?.ProductDescription}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    alignContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  {/* <Typography
                                                sx={{
                                                  ...ProductPriceTextStyle,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img
                                                  src={stackofcoin}
                                                  alt="rupieicon"
                                                  style={{
                                                    width: "18px",
                                                    height: "auto",
                                                  }}
                                                />
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  ...ProductPriceTextStyle,
                                                  marginTop: "-03px",
                                                }}
                                              >
                                                &nbsp;
                                                <CommaSeprator
                                                  Price={
                                                    variationWithHighestTotal?.DiscountedPrice
                                                  }
                                                />
                                              </Typography> */}
                                  <Typography
                                    sx={{
                                      ...ProductPriceTextStyle,
                                      marginTop: "-03px",
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "2px",
                                      width: "100%",
                                    }}
                                  >
                                    &nbsp;
                                    <span
                                      style={{
                                        ...ProductNameTextStyle,
                                        fontSize: "15px",
                                      }}
                                    >
                                      Max Order Quantity :
                                    </span>{" "}
                                    &nbsp;
                                    <CommaSeprator
                                      Price={sums?.maxOrderQtySum}
                                    />
                                    &nbsp;
                                    <span
                                      style={{
                                        ...ProductNameTextStyle,
                                        fontSize: "15px",
                                      }}
                                    >
                                      Total Product Value :
                                    </span>{" "}
                                    &nbsp;
                                    <CommaSeprator
                                      Price={sums?.totalValueSum}
                                    />
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: "60px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              {ProductId === el._id ? (
                                <Box
                                  onClick={() => {
                                    setProductId(null);
                                    sendEvents("Click on radio check");
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <img src={radiocheck} size={30} />
                                </Box>
                              ) : (
                                <Box
                                  onClick={() => {
                                    setProductId(el?._id);
                                    setProductData(el);
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <img src={radiouncheck} size={30} />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        color: "#8A8A8A",
                        lineHeight: 20,
                      }}
                    >
                      No Data Found
                    </Typography>
                  </Box>
                )}
              </DialogContent>
              <DialogTitle id="responsive-dialog-title">
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ ...reqbtn, width: "auto" }}
                    disabled={ProductId ? false : true}
                    onClick={() => {
                      setOpenSecond(true);
                      setOpen(false);
                    }}
                  >
                    Put In Ready Stock
                  </Button>
                </Box>
              </DialogTitle>
            </Dialog>
            <Dialog
              open={openSecond}
              sx={{
                backdropFilter: "blur(2px)",
              }}
              fullWidth
              maxWidth="lg"
              PaperProps={{
                sx: {
                  width: "60%",
                  height: "auto",
                  minHeight: "80%",
                  maxHeight: "80%",
                  borderRadius: "20px",
                  justifyContent: "center",
                },
              }}
            >
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  // bgcolor: "green",
                }}
              >
                <DialogContentText>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "20px",
                        color: "#7D8BA6",
                        textAlign: "center",
                      }}
                    >
                      <b>
                        <u>CREDIT LINE AGREEMENT FOR THE BARTER COINS</u>
                      </b>
                    </Typography>
                    <PrivateDealAgreement
                      OrderData={VoucherSummaryData}
                      ProductData={ProductData}
                      CouponCodeData={CouponCodeData}
                    />
                    <Typography sx={credittermstext}>
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: "20px" },
                          padding: "1px",
                        }}
                        onClick={() => {
                          if (check === false) {
                            setCheck(true);
                          } else {
                            setCheck(false);
                          }
                        }}
                      />
                      I agree to all the clauses of this Credit Line Agreement
                      for Barter Coins as set forth above.
                    </Typography>
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogTitle id="responsive-dialog-title">
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      ...reqbtn,
                      fontWeight: 700,
                      background: "none",
                      border: "0.7px solid #EBEDEE",
                      color: "#445FD2",
                    }}
                    onClick={() => {
                      if (open === false) {
                        setOpen(true);
                        setOpenSecond(false);
                      } else {
                        setOpen(false);
                        setOpenSecond(false);
                      }
                    }}
                  >
                    Decline
                  </Button>
                  <Button
                    variant="contained"
                    sx={reqbtn}
                    disabled={check ? false : true}
                    onClick={() => {
                      // setOpenAddressModal(true);
                      setOpenSecond(false);
                      UpdateOrderWithOrder(CouponCodeData);
                      UpdateInstantBalance(CouponCodeData);
                      UpdateProductReadyStock();
                    }}
                  >
                    I Agree
                  </Button>
                </Box>
              </DialogTitle>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default VoucherOrderSummary;

const reqbtn = {
  width: "100px",
  height: "40px",
  boxShadow: "none",
  background: "#445FD2",
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "24px",
  textTransform: "none",
};

const mainbox = {
  width: "100%",
  height: "48px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const headbox = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const contentbox = {
  width: "100%",
  height: "80%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // gap: "3px",
};

const headbox2 = {
  width: "100%",
  height: "64px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const selername = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  opacity: 0.7,
};

const mapbox = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const elitem = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const elvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const detailtext = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const detailtext2 = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#6B7A99",
  opacity: 0.4,
};

const gridstyle = {
  border: "1px solid rgba(24, 2, 12, 0.05)",
  borderRadius: "10px 10px 0px 0",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  opacity: 0.5,
  color: "#8384a1",
  opacity: 1,
};

const AddressInputTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  marginBottom: "10px",
  color: "#6B7A99",
};

const AddressInputStyle = {
  width: "186px",
  height: "35px",
  background: "#F9F9F9",
  borderRadius: "6px",
  border: "none",
  paddingLeft: "10px",
};
const ProductNameTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "18px",
    lg: "18px",
    md: "17px",
    sm: "13px",
    xs: "13px",
  },
  lineHeight: "25px",
  color: "#6B7A99",
  textAlign: "left",
};

const ProductMetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: {
    xl: "12px",
    lg: "12px",
    md: "11px",
    sm: "9px",
    xs: "9px",
  },
  lineHeight: {
    xl: "18px",
    lg: "18px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  color: "#858585",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  color: "rgba(107, 122, 153, 0.5)",
  fontSize: {
    xl: "15px",
    lg: "15px",
    md: "15px",
    sm: "15px",
    xs: "15px",
  },
  lineHeight: {
    xl: "21px",
    lg: "21px",
    md: "19px",
    sm: "18px",
    xs: "17px",
  },
  alignContent: "center",
  alignItems: "center",
};

const credittermstext = {
  width: "100%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "13px",
    lg: "13px",
    md: "10px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "20px",
  textAlign: "left",
  color: "#7D8BA6",
};
