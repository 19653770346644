import React from 'react';
import * as Styles from '../Styles';
import { Box, Grid, Typography, Paper } from '@mui/material';
import leftArr from '../../../assets/Images/LearnBarterImages/leftArr.svg';
import smallbxilogo from '../../../assets/Images/LearnBarterImages/smallbxilogo.svg';
const IntroLearnBarter = () => {
  const heroText = [
    'Buy and Sell on BXI Marketplace',
    'Not a Legal Tender',
    'For Accounting Purposes',
    'Valid Inside Marketplace',
    'Medium of Exchange',
    '1 Trade Credit = 1 INR',
  ];
  return (
    <Paper elevation={0} sx={{ mt: { xl: 10, lg: 10, md: 10, sm: 0, xs: 0 } }}>
      <Grid container spacing={10}>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Typography
            sx={{
              ...Styles.smallHeadText,
              width: {
                xl: '90%',
                lg: '90%',
                md: '100%',
                sm: '100%',
                xs: '100%',
              },
              mx: 'auto',
            }}
          >
            FOR BUSINESS
          </Typography>
          <Typography
            sx={{
              ...Styles.headText,
              width: {
                xl: '90%',
                lg: '90%',
                md: '100%',
                sm: '100%',
                xs: '100%',
              },
              mx: 'auto',
            }}
          >
            Introducing BXI
            <span style={{ color: '#6C6CA2' }}>&nbsp;Trade Credits</span>
          </Typography>
          <Box
            component={'iframe'}
            src="https://www.youtube.com/embed/SDVXjAgnZFQ"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            sx={{
              width: {
                xl: '78%',
                lg: '78%',
                md: '78%',
                sm: '100%',
                xs: '100%',
              },
              mx: 'auto',
              height: '55vh',
              objectFit: 'cover',
              border: 'none',
              mt: 2,
              ml: { xl: 1, lg: 1, md: 1, sm: 0, xs: 0 },
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 3, // Add consistent spacing
              alignItems: 'flex-start',
              width: {
                xl: '100%',
                lg: '100%',
                md: '100%',
                sm: '80%',
                xs: '80%',
              },
              mx: 'auto',
              mt: { xl: 9, lg: 9, md: 9, sm: 0, xs: 0 },
            }}
          >
            {heroText.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: {
                    xl: '100%',
                    lg: '100%',
                    md: '100%',
                    sm: '60%',
                    xs: '70%',
                  },
                  mr: 'auto',
                  justifyContent: 'flex-start',
                  gap: 2, // Space between logo and text
                }}
              >
                <Box
                  component={'img'}
                  src={smallbxilogo}
                  sx={{
                    height: 'auto', // Adjust based on the logo size in the image
                    width: 'auto', // Ensure the logo is proportional
                  }}
                />
                <Typography sx={Styles.subCompanyText}>{item}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default IntroLearnBarter;
