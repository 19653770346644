import React, { useRef } from 'react';
import * as Styles from '../Styles';
import { Box, Grid, Typography, Paper, Button } from '@mui/material';
import feed from '../../../assets/Images/LearnBarterImages/feed.svg';
import arrowright from '../../../assets/Images/LearnBarterImages/arrowright.svg';
import scrollLeft from '../../../assets/Images/LearnBarterImages/scrollLeft.svg';
import { read } from 'xlsx';
const NewsBlogs = () => {
  const scrollBoxRef = useRef(null);
  const scrollForward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: 500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };

  const scrollBackward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: -500, behavior: 'smooth' }); // Adjust the scroll amount as needed
    }
  };
  const blogsData = [
    {
      id: 1,
      title: 'Web Interface',
      description:
        'Advantages and disadvantages of the barter system of exchange',
      readMore: '/difference-between-barter-exchange-and-money-exchange',
    },
    {
      id: 2,
      title: 'Web Interface',
      description: ' The Difference Between Barter Exchange and Money Exchange',
      readMore: '/difference-between-barter-exchange-and-money-exchange',
    },
    {
      id: 3,
      title: 'Web Interface',
      description:
        ' The Ultimate Guide to Barter Exchange goods without involving money',
      readMore: '/barter-exchange-goods-without-involving-money',
    },
    {
      id: 4,
      title: 'Web Interface',
      description: ' Barter trade Companies',
      readMore: '/barter-trade-companies',
    },
    {
      id: 5,
      title: 'Web Interface',
      description:
        'Advantages and disadvantages of the barter system of exchange',
      readMore: '/difference-between-barter-exchange-and-money-exchange',
    },
    {
      id: 6,
      title: 'Web Interface',
      description: ' The Difference Between Barter Exchange and Money Exchange',
      readMore: '/difference-between-barter-exchange-and-money-exchange',
    },
    {
      id: 7,
      title: 'Web Interface',
      description:
        ' The Ultimate Guide to Barter Exchange goods without involving money',
      readMore: '/barter-exchange-goods-without-involving-money',
    },
    {
      id: 8,
      title: 'Web Interface',
      description: ' Barter trade Companies',
      readMore: '/barter-trade-companies',
    },
  ];
  return (
    <Paper elevation={0}>
      <Box sx={{ mt: 5 }}>
        <Typography
          sx={{
            ...Styles.smallHeadText,
            width: {
              xl: '90%',
              lg: '90%',
              md: '100%',
              sm: '100%',
              xs: '100%',
            },
            mx: 'auto',
          }}
        >
          INSIGHTS & TIPS FROM BXI
        </Typography>
        <Typography sx={{ ...Styles.headTextColor, width: '90%', mx: 'auto' }}>
          Newsfeed & Blogs
        </Typography>
      </Box>
      <Box
        ref={scrollBoxRef}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          mt: 5,
          overflowX: 'auto',
          gap: '20px',
          width: '98%',
          mx: 'auto',
          whiteSpace: 'nowrap',
          ml: 2,
          mr: 2,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {blogsData.map((item, index) => (
          <Box sx={Styles.newsblogBox}>
            <Box
              component={'img'}
              src={feed}
              sx={{ height: '100%', width: '100%', maxHeight: '190px' }}
            />
            <Typography sx={Styles.boxColorText}>{item?.title}</Typography>
            <Typography sx={Styles.boxColorText2}>
              {item?.description}
            </Typography>
            <Box
              component={'a'}
              href={item?.readMore}
              target="_blank"
              alt="wtsp"
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '10px',
                alignItems: 'center',
                mr: 'auto',
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <Typography sx={Styles.boxColorText3}>Read More</Typography>
              <Box
                component={'img'}
                src={arrowright}
                sx={{ height: 'auto', maxWidth: '20px' }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={scrollStyles}>
        <Box
          component={'img'}
          src={scrollLeft}
          sx={{
            height: 'auto',
            maxWidth: 'auto',
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.6,
            },
          }}
          onClick={scrollBackward}
        />
        <Box
          component={'img'}
          src={scrollLeft}
          sx={{
            height: 'auto',
            maxWidth: 'auto',
            transform: 'rotate(180deg)',
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.6,
            },
          }}
          onClick={scrollForward}
        />
      </Box>
    </Paper>
  );
};

export default NewsBlogs;

const scrollStyles = {
  display: 'flex',
  justifyContent: {
    xl: 'flex-start',
    lg: 'flex-start',
    md: 'flex-start',
    sm: 'center',
    xs: 'center',
  },
  mt: 3,
  gap: '30px',
  width: '90%',
  mx: 'auto',
};
