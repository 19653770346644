import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography, Paper } from '@mui/material';
import { Fade } from 'react-reveal';
import backshadowimage from '../../assets/HomePageImages/backshadowimage.svg';
import visionimg from '../../assets/HomePageImages/visionimg.svg';
import misionimg from '../../assets/HomePageImages/misionimg.svg';
import { BoxStyle } from '../../pages/AddProductAllPages/MediaOnline/Styles';

function About(props) {
  return (
    <Paper elevation={0}>
      <Box sx={{ margin: '50px 5px' }}>
        <Grid
          container
          spacing={4}
          sx={{ paddingTop: '55px' }}
          ref={props.scollRef}
        >
          {/* Text Content Section */}
          <Grid item xs={12} lg={6}>
            <Box sx={{ marginLeft: { lg: '60px', xs: '10px' } }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: {
                    lg: 'flex-start',
                    xs: 'center',
                  },
                }}
              >
                <Fade top duration={1000}>
                  <Typography
                    component="span"
                    sx={{
                      ...headingStyle,
                      fontSize: { lg: '30px', xs: '25px' },
                    }}
                  >
                    What is
                  </Typography>
                  <Typography component="span" sx={gradientNewText}>
                    &nbsp;BXI ?
                  </Typography>
                </Fade>
              </Box>
              <Typography sx={contentStyle}>
                <span style={boldTextStyle}>
                  BXI, which stands for Barter Exchange of India,
                </span>{' '}
                is a digital marketplace for businesses in India, where
                companies come together to exchange products using "Trade
                credits"
                {/* <Link to="/howitworks" style={{ textDecoration: 'none' }}>
                </Link> */}
                .
              </Typography>
              <Typography sx={contentStyle}>
                BXI acts as a third-party record keeper of transactions and
                makes it easy for businesses to exchange what they have for what
                they need.
              </Typography>
              <Typography sx={contentStyle}>
                Organizations pay by products for what they want in exchange.
              </Typography>
              <Typography sx={{ ...contentStyle, mt: 1 }}>
                BXI is a business community where reputed organizations join to
                exchange goods and services, creating a vibrant marketplace
                where businesses trade without cash.
              </Typography>
            </Box>
          </Grid>

          {/* Video Section */}
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                maxWidth: { lg: '600px', xs: '350px' },
                mx: 'auto',
                mt: 2,
              }}
            >
              <Box
                component="img"
                src={backshadowimage}
                alt="Background Shadow"
                sx={{
                  display: {
                    xl: 'block',
                    lg: 'block',
                    md: 'none',
                    sm: 'none',
                    xs: 'none',
                  },
                  position: 'absolute',
                  top: -60,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 0,
                }}
              />
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '338px',
                  maxWidth: { lg: '600px', xs: '350px' },
                  zIndex: 1,
                  transform: 'rotate(90deg)',
                }}
              >
                <iframe
                  src="https://www.youtube.com/embed/F0pISDazeck?si=c3W9zHfaCduypKTI"
                  style={{
                    width: '100%',
                    height: '100%',
                    transform: 'rotate(-90deg)',
                    border: 'none',
                  }}
                  allowFullScreen
                />
              </Box>
              {/* <Box
                component={'iframe'}
                src="https://www.youtube.com/embed/F0pISDazeck?si=c3W9zHfaCduypKTI"
                sx={{
                  width: '100%',
                  maxHeight: {
                    xl: '338px',
                    lg: '338px',
                    md: '438px',
                    sm: '200px',
                    xs: '200px',
                  },
                  border: 'none',
                }}
              /> */}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Vision and Mission Section */}

      <Box sx={BoxStyleGrident}>
        <Box
          sx={{
            ...insideBox,
            background:
              'linear-gradient(180deg, rgba(238, 234, 254, 0.3) 0%, rgba(255, 255, 255, 1) 100%)',
          }}
        >
          <Box
            component={'img'}
            src={visionimg}
            alt=""
            sx={{ width: 'auto', height: 'auto' }}
          />
          <Typography sx={boxHeadText}>Vision</Typography>
          <Typography sx={boxSubText}>
            To revolutionize the B2B landscape by pioneering India's first
            online barter marketplace, facilitating seamless exchanges and
            creating a vibrant ecosystem where businesses trade goods, earn
            Trade credits , and unlock limitless possibilities for growth and
            prosperity.
          </Typography>
        </Box>
        <Box
          sx={{
            ...insideBox,
            background:
              ' linear-gradient(360deg, #FFFFFF 21.64%, #FFF4F9 96.76%)',
          }}
        >
          <Box
            component={'img'}
            src={misionimg}
            alt=""
            sx={{ width: 'auto', height: 'auto', mt: -2 }}
          />
          <Typography sx={boxHeadText}>Mission</Typography>
          <Typography sx={boxSubText}>
            Empowering MSMEs and organizations to thrive through innovative
            digital barter solutions, fostering growth, sustainability, and
            collaboration in the business community
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

export default About;

/** Styles */
const headingStyle = {
  fontFamily: 'Poppins',
  fontWeight: 700,
  fontSize: { lg: '30px', xs: '25px' },
  lineHeight: '65px',
  color: '#0D0E0E',
  textAlign: 'center',
};

const contentStyle = {
  fontFamily: 'Poppins',
  fontSize: { lg: '16px', xs: '14px' },
  color: 'rgba(73, 75, 122, 1)',
  marginBottom: '15px',
};

const boldTextStyle = {
  fontWeight: 'bold',
  color: 'rgba(68, 95, 210, 1)',
};

const gradientNewText = {
  fontFamily: 'Poppins',
  fontWeight: 500,
  fontSize: { lg: '32px', xs: '25px' },
  lineHeight: '65px',
  color: '#6C6CA2',
};

const boxHeadText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '14px' },
  fontWeight: 600,
  color: 'rgba(51, 51, 51, 1)',
};

const boxSubText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '13px', lg: '13px', md: '10px', sm: '10px', xs: '10px' },
  fontWeight: 400,
  color: 'rgba(73, 75, 122, 1)',
  textAlign: 'center',
};

const BoxStyleGrident = {
  width: { xl: '90%', lg: '90%', md: '90%', sm: '85%', xs: '85%' },
  mx: 'auto',
  mt: 5,
  boxShadow: '0px 10px 20px rgba(100, 116, 140, 0.12)',
  borderRadius: '20px',
  p: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'row',
    sm: 'column',
    xs: 'column',
  },
  gap: { xl: 2, lg: 2, md: 2, sm: 6, xs: 6 },
  mb: 4,
};

const insideBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 2,
  p: 2,
  width: { xl: '100%', lg: '100%', md: '100%', sm: '90%', xs: '90%' },
  borderRadius: '20px',
  minHeight: '200px',
  maxHeight: '200px',
  // boxShadow: '0px 10px 20px rgba(100, 116, 140, 0.12)',
};
