import React from 'react';
import * as Styles from '../Styles';
import { Box, Grid, Typography, Paper } from '@mui/material';
import flowhowitworks from '../../../assets/Images/LearnBarterImages/flowhowitworks.svg';
import rightArr from '../../../assets/Images/LearnBarterImages/rightArr.svg';
const HeroLearnBarter = () => {
  return (
    <Paper elevation={0} sx={{ mt: 10 }}>
      <Paper
        elevation={0}
        sx={{
          //   height: {
          //     xl: '80vh',
          //     lg: '80vh',
          //     md: '80vh',
          //     sm: '100vh',
          //     xs: '100vh',
          //   },
          width: '100%',
          mt: { xl: 0, lg: 0, md: 0, sm: 10, xs: 10 },
          mb: { xl: 0, lg: 0, md: 5, sm: 2, xs: 2 },
        }}
      >
        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            sm={12}
            sx={{ mt: { xl: 2, lg: 2, md: 2, sm: 2, xs: 2 } }}
          >
            <Typography
              sx={{
                ...Styles.headText,
                width: {
                  xl: '90%',
                  lg: '90%',
                  md: '100%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
              }}
            >
              How Does
              <span style={{ color: '#6C6CA2' }}>&nbsp;BXI Work ?</span>
            </Typography>
            <Box
              component={'iframe'}
              src="https://www.youtube.com/embed/SDVXjAgnZFQ"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              sx={{
                width: {
                  xl: '100%',
                  lg: '100%',
                  md: '100%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
                height: '55vh',
                objectFit: 'cover',
                border: 'none',
                mt: 2,
                ml: { xl: 1, lg: 1, md: 0, sm: 0, xs: 0 },
              }}
            />
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            sm={12}
            sx={{
              mb: { xl: 0, lg: 0, md: 0, sm: 4, xs: 4 },
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                height: '100%',
                width: '30%',
                backgroundImage: `url(${rightArr})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                zIndex: 0,
                display: {
                  xl: 'block',
                  lg: 'block',
                  md: 'none',
                  sm: 'none',
                  xs: 'none',
                },
              }}
            />
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <Box
                component={'img'}
                src={flowhowitworks}
                sx={{
                  width: '100%',
                  mx: 'auto',
                  maxWidth: '100%',
                  height: '70%',
                  maxHeight: {
                    xl: '50vh',
                    lg: '50vh',
                    md: '50vh',
                    sm: '40vh',
                    xs: '40vh',
                  },
                  display: 'flex',
                  justifyContent: 'center',
                  mt: { xl: 12, lg: 12, md: 10, sm: 2, xs: 2 },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default HeroLearnBarter;
