import React from "react";
import { Box } from "@mui/material";
const ClientBox = ({ image, name = "client" }) => {
  return (
    <Box>
      <img
        style={{
          width: {
            xl: "155px",
            lg: "155px",
            md: "155px",
            sm: "120px",
            xs: "120px",
          },
          height: "55px",
          objectFit: "cover",
          margin: {
            xl: "30px",
            lg: "30px",
            md: "30px",
            sm: "20px",
            xs: "20px",
          },
        }}
        src={image}
        alt="android"
      />
    </Box>
  );
};

export default ClientBox;
