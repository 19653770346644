import { Box, Grid, Typography, Button } from '@mui/material';
import React, { useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom/dist';
import BxiWhiteLogo from '../../assets/HomePageImages/BXI_WHITE_LOGO.svg';
import callfooter from '../../assets/HomePageImages/callfooter.svg';
import messagefooter from '../../assets/HomePageImages/messagefooter.svg';
import IRTA_LOGO from '../../assets/HomePageImages/IRTA_LOGO.png';
import phone from '../../assets/HeaderIcon/phone.svg';
import messageicon from '../../assets/HeaderIcon/messageicon.svg';
import insta from '../../assets/HeaderIcon/insta.svg';
import fbicon from '../../assets/HeaderIcon/fbicon.svg';
import linkdinicon from '../../assets/HeaderIcon/linkdinicon.svg';
import twiticon from '../../assets/HeaderIcon/twiticon.svg';
import youtubeicon from '../../assets/HeaderIcon/youtubeicon.svg';
import NDA from '../../assets/HomePageImages/NDA.png';
import pdfimgnew from '../../assets/HomePageImages/pdfimgnew.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import NewTwitter from '../../assets/twitterVector.svg';
import YouTubeIcon from '@mui/icons-material/YouTube';
import fb from '../../assets/HomePageImages/fb.svg';
import { keyframes } from '@emotion/react';
const Footer = (props) => {
  const navigate = useNavigate();
  const Location = useLocation();
  const buttonAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;
  async function openEmail(e) {
    window.location.href = 'mailto:business@bxiworld.com';
  }

  return (
    <>
      <Grid
        container
        sx={{
          background: 'rgba(255, 255, 255, 1)',
          p: 1,
        }}
      >
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xl: 'flex-start',
                lg: 'flex-start',
                md: 'center',
                sm: 'center',
                xs: 'center',
              },
              width: '90%',
              mx: 'auto',
            }}
          >
            <img src={BxiWhiteLogo} width={50} alt="BxiWhiteLogo" />
          </Box>
          <Box>
            <Typography
              sx={{
                ...FooterText,
                width: '90%',
                mx: 'auto',
                lineHeight: '50px',
              }}
            >
              Address
            </Typography>
            <Typography
              sx={{
                ...textDesign,
                width: '90%',
                mx: 'auto',
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
              }}
            >
              501- Meadows Tower, Sahar Plaza , Sir M.V. Road, Andheri (E),
              Mumbai 400059.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                ...FooterText,
                width: '90%',
                mx: 'auto',
                lineHeight: '50px',
              }}
            >
              Contact Us
            </Typography>
            <a
              href="https://api.whatsapp.com/send/?phone=9136436776&text&type=phone_number&app_absent=0"
              target="_blank"
              alt="wtsp"
              style={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '95%',
                  mx: 'auto',
                  gap: '10px',
                  justifyContent: {
                    xl: 'flex-start',
                    lg: 'flex-start',
                    md: 'center',
                    sm: 'center',
                    xs: 'center',
                  },
                }}
              >
                <img
                  src={phone}
                  alt="wtsp"
                  style={{ width: '15px', height: 'auto', marginLeft: '07px' }}
                />

                <Typography sx={{ ...textDesign, mt: 0.5 }}>
                  +91 9136436776
                </Typography>
              </Box>
            </a>
            <Box
              onClick={openEmail}
              sx={{
                display: 'flex',
                width: '95%',
                mx: 'auto',
                gap: '10px',
                cursor: 'pointer',
                mt: 0,
                justifyContent: {
                  xl: 'flex-start',
                  lg: 'flex-start',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
              }}
            >
              <img
                src={messageicon}
                alt=""
                style={{ width: '17px', height: 'auto', marginLeft: '07px' }}
              />
              <Typography sx={{ ...textDesign, mt: 0.4 }}>
                business@bxiworld.com
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          sx={{ pt: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 } }}
        >
          <Box sx={{ mt: { xl: 5, lg: 5, md: 0, sm: 0, xs: 0 } }}>
            <Typography
              sx={{
                ...FooterText,
                textAlign: 'center',
                mt: {
                  xl: -5,
                  lg: -5,
                  md: 0,
                  sm: 0,
                  xs: 0,
                },
              }}
            >
              Helpful Links
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                width: '100%',
                ml: { xl: '35%', lg: '35%', md: '0', sm: '0', xs: '0' },
              }}
            >
              <Box
                onClick={() => {
                  navigate('/about');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                sx={{
                  ...textDesign,
                  cursor: 'pointer',

                  mx: 'auto',
                  lineHeight: '25px',
                }}
              >
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  About Us
                </Typography>
              </Box>
              <Link to="/blogpage" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  Blog
                </Typography>
              </Link>
              <Link to="/termsconditions" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  Terms & Conditions
                </Typography>
              </Link>
              <Link to="/privacypolicy" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  Privacy Policy
                </Typography>
              </Link>
              <Link to="/contactus" style={{ textDecoration: 'none' }}>
                <Typography
                  sx={{
                    ...textDesign,
                    mx: 'auto',
                    lineHeight: '25px',
                    textAlign: {
                      xl: 'left',
                      lg: 'left',
                      md: 'center',
                      sm: 'center',
                      xs: 'center',
                    },
                  }}
                >
                  Contact us
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          sx={{ pt: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 } }}
        >
          <Box sx={{ mt: { xl: 6, lg: 6, md: 1, sm: 1, xs: 1 } }}>
            <Typography
              sx={{
                ...FooterText,
                mt: {
                  xl: -5,
                  lg: -5,
                  md: 0,
                  sm: 0,
                  xs: 0,
                },
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                lineHeight: '20px',
                width: {
                  xl: '60%',
                  lg: '60%',
                  md: '60%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
              }}
            >
              BXI World LLP
            </Typography>
            <Typography
              sx={{
                color: '#fff',
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                width: {
                  xl: '60%',
                  lg: '60%',
                  md: '60%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
                fontSize: '9px',
                fontFamily: 'Poppins',
                fontWeight: 200,
                color: 'rgba(18, 18, 18, 1)',
              }}
            >
              Registered Name
            </Typography>
            <Typography
              sx={{
                ...textDesign,
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                width: {
                  xl: '60%',
                  lg: '60%',
                  md: '60%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
                lineHeight: '25px',
              }}
            >
              PAN: AAXFB2929C
            </Typography>
            <Typography
              sx={{
                ...textDesign,
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                width: {
                  xl: '60%',
                  lg: '60%',
                  md: '60%',
                  sm: '100%',
                  xs: '100%',
                },
                mx: 'auto',
                lineHeight: '25px',
              }}
            >
              GST: 27AAXFB2929C1ZA
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          sx={{ pt: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 } }}
        >
          <Box
            sx={{
              mt: { xl: 5, lg: 5, md: 0, sm: 0, xs: 0 },
              width: '340px',
              mx: 'auto',
              textAlign: 'center',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  ...FooterText,
                  lineHeight: '20px',
                  mt: {
                    xl: -5,
                    lg: -5,
                    md: 2,
                    sm: 2,
                    xs: 2,
                  },
                  ml: 1,
                }}
              >
                "Member of Global Barter Council"
              </Typography>
              <a href="https://www.irta.com/member-directory/" target="_blank">
                <img
                  src={IRTA_LOGO}
                  style={{
                    width: '150.68px',
                    height: '50.88px',
                    textAlign: 'center',
                  }}
                  alt="imageAlt"
                />
              </a>
            </Box>
          </Box>
        </Grid>
        <Box
          sx={{
            borderBottom: '1px solid rgba(214, 214, 214, 1)',
            width: '98%',
            mx: 'auto',
            mt: 2,
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xl: 'row',
              lg: 'row',
              md: 'row',
              sm: 'column',
              xs: 'column',
            },
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xl: 'flex-end',
                lg: 'flex-end',
                md: 'flex-end',
                sm: 'center',
                xs: 'center',
              },
              width: {
                xl: '80%',
                lg: '80%',
                md: '80%',
                sm: '100%',
                xs: '100%',
              },
              mt: 1,
            }}
          >
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Box
                sx={{
                  ...socialIcons,
                }}
              >
                <a
                  href=" https://www.facebook.com/bxi.world?mibextid=ZbWKwL"
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box
                    component={'img'}
                    src={insta}
                    alt="insta"
                    sx={{ height: 'auto', width: 'auto' }}
                  />
                </a>
              </Box>
              <Box sx={socialIcons}>
                <a
                  href="https://instagram.com/bxi.world?igshid=MzRlODBiNWFlZA== "
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box
                    component={'img'}
                    src={fbicon}
                    alt="insta"
                    sx={{ height: 'auto', width: 'auto' }}
                  />
                </a>
              </Box>
              <Box sx={socialIcons}>
                <a
                  href=" https://www.linkedin.com/company/barter-exchange-of-india/"
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box
                    component={'img'}
                    src={linkdinicon}
                    alt="insta"
                    sx={{ height: 'auto', width: 'auto' }}
                  />
                </a>
              </Box>
              <Box sx={socialIcons}>
                <a
                  href="https://twitter.com/bximarketplace?s=20"
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box
                    component={'img'}
                    src={twiticon}
                    alt="twiticon"
                    sx={{ height: 'auto', width: 'auto' }}
                  />
                </a>
              </Box>
              <Box sx={socialIcons}>
                <a
                  href="https://www.youtube.com/@bxiworld"
                  target="_blank"
                  alt="wtsp"
                  style={{
                    color: 'inherit',
                    marginTop: '3px',
                  }}
                >
                  <Box
                    component={'img'}
                    src={youtubeicon}
                    alt="youtubeicon"
                    sx={{ height: 'auto', width: 'auto' }}
                  />
                </a>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xl: 'flex-end',
                lg: 'flex-end',
                md: 'flex-end',
                sm: 'center',
                xs: 'center',
              },
              width: {
                xl: '80%',
                lg: '80%',
                md: '80%',
                sm: '100%',
                xs: '100%',
              },
            }}
          >
            <Typography
              sx={{
                ...textDesign,
                textAlign: {
                  xl: 'right',
                  lg: 'right',
                  md: 'right',
                  sm: 'center',
                  xs: 'center',
                },
                fontSize: {
                  xl: '14px',
                  lg: '14px',
                  md: '14px',
                  sm: '12px',
                  xs: '12px',
                },
                mt: 1.3,
              }}
            >
              @{new Date().getFullYear()} BXI World LLP. All Rights Reserved.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Footer;
const FooterText = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: { xl: '18px', lg: '18px', md: '18px', sm: '16px', xs: '16px' },
  lineHeight: '35px',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
  color: 'rgba(18, 18, 18, 1)',
};
const socialIcons = {
  width: '32.39px',
  height: '32.39px',
  // background: ' #fff',
  borderRadius: '50%',
  color: '#156DB6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  // '&:hover': {
  //   background: '#000',
  //   transform: 'ease 1s',
  // },
};
const textDesign = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: { xl: '14px', lg: '14px', md: '13px', sm: '13px', xs: '13px' },
  lineHeight: '18px',
  color: 'rgba(0, 0, 0, 1)',
};
