import React from 'react';
import * as Styles from '../Styles';
import { Box, Grid, Typography, Paper } from '@mui/material';
import star from '../../../assets/Images/LearnBarterImages/star.svg';
const Trade = () => {
  return (
    <Paper elevation={0} mt={10}>
      <Typography sx={{ ...Styles.headMainText, width: '95%' }}>
        Apply Trade Limit - Buy First
      </Typography>
      <Box
        sx={{
          width: '90%',
          height: '100%',
          mx: 'auto',
          mt: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: {
            xl: 'flex-end',
            lg: 'flex-end',
            md: 'flex-end',
            sm: 'center',
            xs: 'center',
          },
          flexDirection: {
            xl: 'row',
            lg: 'row',
            md: 'row',
            sm: 'column',
            xs: 'column',
          },
          gap: '10px',
        }}
      >
        <Box sx={boxStyleGrident}>Select product, apply for limit</Box>
        <Box sx={{ ...boxStyleGrident, height: '80px' }}>
          Agree to avail limit
        </Box>
        <Box sx={{ ...boxStyleGrident, height: '100px' }}>
          Trade limit processed
        </Box>
        <Box sx={{ ...boxStyleGrident, height: '120px' }}>
          Product tagged as ready stock
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Box
            component={'img'}
            src={star}
            alt={'start'}
            sx={{ width: 'auto', height: 'auto' }}
          />
          <Box sx={{ ...boxStyleGrident, height: '140px', fontWeight: 600 }}>
            Start to buy first !
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default Trade;

const boxStyleGrident = {
  width: { xl: '180px', lg: '180px', md: '100px', sm: '240px', xs: '240px' },
  height: '60px',
  background:
    'linear-gradient(180deg, #E7F0FF 3.65%, #F5F0FF 57.29%, #FFFFFF 100%)',
  display: 'flex',
  justifyContent: 'center',
  // alignItems: 'center',
  borderRadius: '10px',
  fontFamily: 'Poppins',
  fontSize: {
    xl: '18px',
    lg: '18px',
    md: '12px',
    sm: '10px',
    xs: '10px',
  },
  fontWeight: 400,
  p: 3,
  color: 'rgba(73, 75, 122, 1)',
  textAlign: 'center',
};
