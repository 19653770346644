import React, { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { notifications } from "../../redux/action/Notification/notification";
import { SendMessageOnWhatsApp } from "../../redux/action/WhatApp/SendMessages";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetOrderSummaryByIdForBuyer } from "../../Hooks/OrderActions/useGetOrderSummaryByIdForBuyer";
import { useMutatePurchaseOrder } from "../../Hooks/PurchaseOrderActions/useMutatePurchaseOrder";
import BoxIcon from "../../assets/Transportation/BoxIcon.svg";
import BubbleWrap from "../../assets/Transportation/BubbleWrap.svg";
import ChecklistIcon from "../../assets/Transportation/ChecklistIcon.svg";
import CompliantIcon from "../../assets/Transportation/CompliantIcon.svg";
import EwayIcon from "../../assets/Transportation/E-wayIcon.svg";
import FragileItemIcon from "../../assets/Transportation/FragileItemIcon.svg";
import HandshakeIcon from "../../assets/Transportation/HandshakeIcon.svg";
import NotificationIcon from "../../assets/Transportation/NotificationIcon.svg";
import PickupConfirmation from "../../assets/Transportation/PickupConfirmation.svg";
import RecordingIcon from "../../assets/Transportation/RecordingIcon.svg";
import ShieldIcon from "../../assets/Transportation/ShieldIcon.svg";
import TransportationIcon from "../../assets/Transportation/TransportationIcon.svg";
import CloseIconImg from "../../assets/Images/CommonImages/CloseIcon.svg";
import CheckboxIcon from "../../assets/Images/CommonImages/CheckboxIcon.svg";
import CheckedBoxIcon from "../../assets/Images/CommonImages/CheckedBoxIcon.svg";
import CheckedCheckboxIcon from "../../assets/Images/CommonImages/CheckedCheckboxIcon.svg";
import Package from "../../assets/Transportation/package 1.svg";
import copy from "../../assets/Transportation/copy 1.svg";
import GlobalToast from "../../components/Toasts/GlobalToast";

const StyledLabel = styled("span")({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#6B7A99",
});

const POAcceptModal = (props) => {
  let ProductIdHere = props?.ProductId;
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openrejectModal, setOpenRejectModal] = useState(false);
  const [openTextarea, setOpenTextarea] = useState("price");
  const [Reason, setReason] = useState("");
  const [openpartOrder, setOpenPartOrder] = React.useState(false);
  const [notificationOn, setNotificationOn] = useState("");
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [GSTData, setGSTData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`Update_TDS_GST/get_all_gst`);
        setGSTData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  //
  const handleGridScroll = () => {
    // Get the grid element from the ref
    const gridElement = gridRef.current;

    // Check if the grid has been scrolled to a specific position (e.g., 200px from the top)
    if (gridElement.scrollTop >= 300) {
      setIsButtonDisabled(false); // Enable the button
    } else {
      setIsButtonDisabled(true); // Keep the button disabled
    }
  };
  let dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [isTransportation, setIsTransportation] = useState("true");
  const [isPartialState, setIsPartialState] = useState(false);

  const [PackgingDataObject, setPackagingObject] = useState({
    packagingcostgstfee: "",
    packagingweight: "",
    packagingcosts: "",
    numberofboxes: "",
    pickupdate: "",
    boxheight: "",
    boxwidth: "",
    boxlength: "",
  });

  const [trasportationdata, setTransportationdata] = useState({
    transportationfee: "",
    gstFee: "",
    deliveryTime: "",
  });

  const handleInputChange = (event, name) => {
    // check if packaging weight is less than 1 kg then show error
    if (
      name === "packagingweight" &&
      event.target.value < 1 &&
      PackgingDataObject?.packagingweight === ""
    ) {
      setPackagingObject((prevObject) => ({
        ...prevObject,
        [name]: "",
      }));
      return toast.error("Packaging weight should be greater than 1 kg", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (event?.$d) {
      setPackagingObject((prevObject) => ({
        ...prevObject,
        [name]: event?.$d,
      }));
    } else {
      const { value } = event?.target;
      setPackagingObject((prevObject) => ({
        ...prevObject,
        [name]: value,
      }));
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const BoxesInsideModal = [
    {
      id: 1,
      image: BoxIcon,
      text: "Seller must have the package ready within 48 hours of accepting the order.",
    },
    {
      id: 2,
      image: EwayIcon,
      text: "Invoice and E- way bills to be shared during pickup.",
    },
    {
      id: 3,
      image: BubbleWrap,
      text: "Seller must ensure proper packaging by using appropriate cushioning, bubble packaging, and waterproof wraps.",
    },
    {
      id: 4,
      image: RecordingIcon,
      text: "Recordings of videos while packaging and applying label/sticker and unboxing with label/sticker is compulsory.",
    },
    {
      id: 5,
      image: TransportationIcon,
      text: "Transport liquid / volatile products at your own risk. In case of damage, the Seller is liable.",
    },
    {
      id: 6,
      image: ShieldIcon,
      text: "The Seller is responsible for safely loading the product into the transport as soon as it arrives.",
    },
    // {
    //   id: 7,
    //   image: NotificationIcon,
    //   text:
    //     "Received damaged goods? Notify the Seller by raising a triparty notification on the BXI platform within 24 hours, including video evidence.",
    // },
    {
      id: 7,
      image: FragileItemIcon,
      text: "Stick the correct stickers to the package denoting fragile cargo, liquid cargo, etc.",
    },
    {
      id: 8,
      image: CompliantIcon,
      text: "If Members file an insurance claim, BXI can assist only with providing proof of facts from the logistics company.",
    },
    {
      id: 9,
      image: ChecklistIcon,
      text: "Please note, BXI does not oversee logistics. You can select your preferred option.",
    },
    {
      id: 10,
      image: HandshakeIcon,
      text: "BXI will connect the Buyer and Seller on payment confirmation, for direct coordination.",
    },
    {
      id: 11,
      image: PickupConfirmation,
      text: "Logistic pick-up by the BXI platform partners is attempted only twice.",
    },
    {
      id: 12,
      image: Package,
      text: " If the package is not damaged externally but the products inside are damaged, the Seller is liable.",
    },
    {
      id: 13,
      image: copy,
      text: " Seller must obtain a signed Manifest copy from the courier pickup agent before the shipment is picked up.",
    },
  ];

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    Array(BoxesInsideModal.length).fill(false)
  );
  const [isAgreeButtonEnabled, setIsAgreeButtonEnabled] = useState(false);

  useEffect(() => {
    // Check if all checkboxes are selected
    setIsAgreeButtonEnabled(selectedCheckboxes.every((value) => value));

    // Save checkbox state to local storage
    localStorage.setItem("checkboxState", JSON.stringify(selectedCheckboxes));
  }, [selectedCheckboxes]);

  const handleCheckboxChange = (index) => {
    const updatedSelectedCheckboxes = [...selectedCheckboxes];
    updatedSelectedCheckboxes[index] = !updatedSelectedCheckboxes[index];
    setSelectedCheckboxes(updatedSelectedCheckboxes);
  };

  const resetCheckboxes = () => {
    setSelectedCheckboxes(Array(BoxesInsideModal.length).fill(false));
  };

  const handleInputChangeTransportation = (event, name) => {
    const { value } = event.target;
    setTransportationdata((prevObject) => ({
      ...prevObject,
      [name]: value,
    }));
  };

  const InputBoxArray = [
    {
      lable: "Packaging Weight(kg) (Min weight 1 Kg)",
      name: "packagingweight",
      size: "full",
      type: "number",
    },
    {
      lable: "Number of Boxes",
      name: "numberofboxes",
      size: "full",
      type: "number",
    },

    {
      lable: "Box Height (cm)",
      name: "boxheight",
      size: "full",
      type: "number",
    },
    {
      lable: "Box Width (cm)",
      name: "boxwidth",
      size: "full",
      type: "number",
    },
    {
      lable: "Box Length (cm)",
      name: "boxlength",
      size: "full",
      type: "number",
    },
    {
      lable: "Pick Up Date",
      name: "pickupdate",
      size: "full",
      type: "date",
    },
    {
      lable: "Total Packaging Cost",
      name: "packagingcosts",
      size: "full",
      type: "number",
    },
    {
      lable: "GST Rate (%)",
      name: "packagingcostgstfee",
      size: "full",
      type: "select",
    },
  ];

  const InputArrayTwo = [
    {
      lable: "Transportation Cost (INR)",
      name: "transportationfee",
      size: "full",
      type: "number",
    },
    {
      lable: "GST Rate (%)",
      name: "gstFee",
      size: "full",
      type: "select",
    },
    {
      lable: "Delivery Time (Days)",
      name: "deliveryTime",
      size: "full",
      type: "number",
    },
  ];

  const { data: OrderSummarydata, isLoading: orderSummaryLoading } =
    useGetOrderSummaryByIdForBuyer(props?.ProductId);

  const [quantity, setQuantity] = useState({
    ProductId: "",
    ProductQty: "",
    ProductsVariantionId: "",
  });

  const [QuantityArr, setQuantityArr] = useState([]);

  const handleCloseRejectModal = () => {
    setOpenRejectModal(false);
  };

  const [InsuranceData, setInsuranceData] = useState();
  const [InsuranceGST, setInsuranceGST] = useState(0);

  async function UpdateOrRejectThePurchaseOrder(props) {
    try {
      const validateValues = (values) =>
        values.some((value) => value == null || value <= 0);

      if (props === "Accepted") {
        // Step 1: Validate Packaging Data
        const isPackagingInvalid = validateValues([
          PackgingDataObject.packagingweight,
          PackgingDataObject.packagingcosts,
          PackgingDataObject.numberofboxes,
          PackgingDataObject.boxheight,
          PackgingDataObject.boxwidth,
          PackgingDataObject.boxlength,
        ]);

        if (isPackagingInvalid) {
          alert(
            "Please fill all required packaging details in the correct format."
          );
          return;
        }

        // Step 2: Check Transportation Data (if applicable)
        if (isTransportation === true) {
          const isTransportationInvalid = validateValues([
            trasportationdata.transportationfee,
            trasportationdata.gstFee,
            trasportationdata.deliveryTime,
          ]);

          if (isTransportationInvalid) {
            alert(
              "Please fill all transportation details in the correct format."
            );
            return;
          }

          if (
            new Date(PackgingDataObject.pickupdate).toJSON() <
            new Date().toJSON()
          ) {
            alert("Pick-up date should be greater than the current date.");
            return;
          }
        }

        // Step 3: API Call for Accepted Orders
        await axios.put(
          `purchase/update_purchase_order_accepted_by_buyer_for_seller_company`,
          {
            data: {
              status: props,
              PackgingDataObject,
              Transportationdata: isTransportation ? trasportationdata : null,
              InsuranceData,
              InsuranceGST,
              OrderSummaryId: ProductIdHere,
              IsPartialOrder: false,
              PartialOrderDetails: QuantityArr,
            },
          },
          { withCredentials: true }
        );

        toast.success("Order Accepted", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
        });
        setTimeout(() => setOpenModal(false), 2000);
        setTimeout(() => navigate("/home/sellerpilist"), 4000);
      } else {
        // Rejection Logic
        await axios.put(
          `purchase/update_purchase_order_accepted_by_buyer_for_seller_company`,
          {
            data: {
              status: props,
              RejectionType: openTextarea,
              RejectionDescription: Reason,
              PackgingDataObject,
              Transportationdata: isTransportation ? trasportationdata : null,
              InsuranceData,
              InsuranceGST,
              OrderSummaryId: ProductIdHere,
              IsPartialOrder: false,
              PartialOrderDetails: QuantityArr,
            },
          },
          { withCredentials: true }
        );

        dispatch(
          SendMessageOnWhatsApp(
            OrderSummarydata?.BuyerDetails?.BuyerCompanyContact,
            "po_rejection",
            "buyer_name",
            OrderSummarydata?.SellerDetails?.SellerCompanyName,
            "dynamic_link3_here",
            "https://bxi.unada.in/home/performainvoice",
            "parameterName2",
            "parameterValue2"
          )
        );

        setOpenRejectModal(false);
        toast.success("Order Rejected", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
    }
  }

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();
  };

  let URL = "";
  const location = useLocation();
  URL = location.pathname;

  return (
    <div>
      <Box
        sx={{
          maxWidth: "500px",
          mx: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          height: "100%",
        }}
      >
        <Button
          onClick={() => handleOpen()}
          sx={{
            ...ButtonStyleForAcceptAndReject,
            background: URL?.includes("sellerdetailedordersummary")
              ? "#B0529C"
              : "#2261a2",
            ":hover": {
              background: "#B0529C",
            },
          }}
        >
          Accept
        </Button>
        <Button
          onClick={() => setOpenRejectModal(true)}
          sx={{
            ...ButtonStyleForAcceptAndReject,
            bgcolor: "#fff",
            border: URL?.includes("sellerdetailedordersummary")
              ? "1px solid #B0529C"
              : "1px solid #2261A2",
            color: URL?.includes("sellerdetailedordersummary")
              ? "#B0529C"
              : "#2261A2",
            "&:hover": {
              background: "none",
            },
          }}
        >
          Reject
        </Button>
        <Modal
          open={openrejectModal}
          onClose={() => setOpenRejectModal(false)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: "translate(0%, 0%)",
          }}
        >
          <Box
            sx={{
              background: "#fff",
              width: "350px",
              height: openTextarea === "" ? "400px" : "480px",
              p: 3,
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#6B7A99",
                  }}
                >
                  Please select the reason of Rejection
                </Typography>
                <Box
                  component="img"
                  src={CloseIconImg}
                  onClick={() => setOpenRejectModal(false)}
                  sx={{
                    cursor: "pointer",
                  }}
                ></Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "15px",
                width: "100%",
              }}
            >
              <Box sx={ButtonGroupstyle}>
                <Box
                  sx={{
                    ...RejectReasonBtnStyle,
                    color: openTextarea == "price" ? "#445FD2" : "#6B7A99",
                    border:
                      openTextarea == "price"
                        ? "1px solid #445FD2"
                        : "1px solid #E4E7EC",
                  }}
                  onClick={() => {
                    setOpenTextarea("price");
                    setReason("");
                  }}
                >
                  <Box
                    sx={{
                      ...TextAndCheckBoxGroupStyle,
                      border: "none",
                    }}
                  >
                    {openTextarea == "price" ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent:
                            openTextarea === "price"
                              ? "space-between"
                              : "flex-start",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component="img"
                            src={CheckedBoxIcon}
                            sx={{
                              width: "25px",
                              height: "25px",
                            }}
                          />
                          <Typography sx={ReasonTextStyle}> Price </Typography>
                        </Box>
                        <Box component="img" src={CheckedCheckboxIcon} />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent:
                            openTextarea === "price"
                              ? "space-between"
                              : "flex-start",
                          gap: "20px",
                        }}
                      >
                        <Box component="img" src={CheckboxIcon} />
                        <Typography sx={ReasonTextStyle}> Price </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <React.Fragment>
                  {openTextarea === "price" ? (
                    <TextField
                      sx={TextAreaStyle}
                      InputProps={InputPropsStyle}
                      placeholder="please explain your reason"
                      multiline
                      rows={4}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                    ></TextField>
                  ) : null}
                </React.Fragment>
              </Box>
              <Box sx={ButtonGroupstyle}>
                <Box
                  sx={{
                    ...RejectReasonBtnStyle,
                    color:
                      openTextarea == "deliverydate" ? "#445FD2" : "#6B7A99",
                    border:
                      openTextarea == "deliverydate"
                        ? "1px solid #445FD2"
                        : "1px solid #E4E7EC",
                  }}
                  onClick={() => {
                    setOpenTextarea("deliverydate");
                    setReason("");
                  }}
                >
                  <Box sx={TextAndCheckBoxGroupStyle}>
                    {openTextarea == "deliverydate" ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent:
                            openTextarea === "deliverydate"
                              ? "space-between"
                              : "flex-start",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component="img"
                            src={CheckedBoxIcon}
                            sx={{
                              width: "25px",
                              height: "25px",
                            }}
                          />
                          <Typography sx={ReasonTextStyle}>
                            {" "}
                            Delivery Date{" "}
                          </Typography>
                        </Box>
                        <Box component="img" src={CheckedCheckboxIcon} />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent:
                            openTextarea === "deliverydate"
                              ? "space-between"
                              : "flex-start",
                          gap: "20px",
                        }}
                      >
                        <Box component="img" src={CheckboxIcon} />
                        <Typography sx={ReasonTextStyle}>
                          {" "}
                          Delivery Date{" "}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <React.Fragment>
                  {openTextarea === "deliverydate" ? (
                    <TextField
                      sx={TextAreaStyle}
                      InputProps={InputPropsStyle}
                      placeholder="please explain your reason"
                      multiline
                      rows={4}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                    ></TextField>
                  ) : null}
                </React.Fragment>
              </Box>
              <Box sx={ButtonGroupstyle}>
                <Box
                  sx={{
                    ...RejectReasonBtnStyle,
                    color: openTextarea == "quantity" ? "#445FD2" : "#6B7A99",
                    border:
                      openTextarea == "quantity"
                        ? "1px solid #445FD2"
                        : "1px solid #E4E7EC",
                  }}
                  onClick={() => {
                    setOpenTextarea("quantity");
                    setReason("");
                  }}
                >
                  <Box sx={TextAndCheckBoxGroupStyle}>
                    {openTextarea == "quantity" ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent:
                            openTextarea === "quantity"
                              ? "space-between"
                              : "flex-start",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component="img"
                            src={CheckedBoxIcon}
                            sx={{
                              width: "25px",
                              height: "25px",
                            }}
                          />
                          <Typography sx={ReasonTextStyle}>
                            {" "}
                            Quantity{" "}
                          </Typography>
                        </Box>
                        <Box component="img" src={CheckedCheckboxIcon} />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent:
                            openTextarea === "quantity"
                              ? "space-between"
                              : "flex-start",
                          gap: "20px",
                        }}
                      >
                        <Box component="img" src={CheckboxIcon} />
                        <Typography sx={ReasonTextStyle}> Quantity </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <React.Fragment>
                  {openTextarea === "quantity" ? (
                    <TextField
                      sx={TextAreaStyle}
                      InputProps={InputPropsStyle}
                      placeholder="please explain your reason"
                      multiline
                      rows={4}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                    ></TextField>
                  ) : null}
                </React.Fragment>
              </Box>
              <Box sx={ButtonGroupstyle}>
                <Box
                  sx={{
                    ...RejectReasonBtnStyle,
                    color: openTextarea == "other" ? "#445FD2" : "#6B7A99",
                    border:
                      openTextarea == "other"
                        ? "1px solid #445FD2"
                        : "1px solid #E4E7EC",
                  }}
                  onClick={() => {
                    setOpenTextarea("other");
                    setReason("");
                  }}
                >
                  <Box sx={TextAndCheckBoxGroupStyle}>
                    {openTextarea == "other" ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent:
                            openTextarea === "other"
                              ? "space-between"
                              : "flex-start",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component="img"
                            src={CheckedBoxIcon}
                            sx={{
                              width: "25px",
                              height: "25px",
                            }}
                          />
                          <Typography sx={ReasonTextStyle}> Other </Typography>
                        </Box>
                        <Box component="img" src={CheckedCheckboxIcon} />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent:
                            openTextarea === "other"
                              ? "space-between"
                              : "flex-start",
                          gap: "20px",
                        }}
                      >
                        <Box component="img" src={CheckboxIcon} />
                        <Typography sx={ReasonTextStyle}> Other </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <React.Fragment>
                  {openTextarea === "other" ? (
                    <TextField
                      sx={TextAreaStyle}
                      InputProps={InputPropsStyle}
                      placeholder="please explain your reason"
                      multiline
                      rows={4}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                      // InputProps={{ border: "none" }}
                    ></TextField>
                  ) : null}
                </React.Fragment>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Button
                onClick={() => setOpenRejectModal(false)}
                sx={{
                  width: "50%",
                  color: "#000",
                  textTransform: "none",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "12px",
                  border: "1px solid #D0D5DD",
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "50%",
                  color: "#fff",
                  background: "#445FD2",
                  textTransform: "none",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "12px",
                  "&:hover": {
                    background: "#445FD2",
                  },
                }}
                onClick={() => {
                  UpdateOrRejectThePurchaseOrder("Rejected");
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          top: "10%",
          width: "80%",
          height: "auto",
          overflowY: "auto",
          mx: "auto",
        }}
      >
        <Paper
          sx={{
            bgcolor: "white",
            width: "100%",
            mx: "auto",
            py: 0,
            height: "100%",
            maxHeight: "80vh",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              float: "right",
              position: "absolute",
              right: "15px",
              top: "10px",
            }}
          >
            <CloseIcon
              sx={{
                color: "#667085",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (open === false) {
                  setOpen(true);
                } else {
                  setOpen(false);
                  setOpenModal(false);
                }
              }}
            />
          </Box>

          <div style={{ background: "white", width: "90%", margin: "auto" }}>
            <Box
              sx={{
                width: "95%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 3,
              }}
            ></Box>
            <Box sx={{ py: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#C64091",
                }}
              >
                Packaging Information
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                alignItems: "center",
                gap: "5%",
                width: "60%",
                flexWrap: "wrap",
              }}
            >
              {InputBoxArray?.map((input, index) => {
                if (input?.type === "date") {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box sx={{ display: "grid", mt: 1 }}>
                        <Typography
                          sx={{ ...FeeBoxText, textAlign: "left", mb: 0.5 }}
                        >
                          {input?.lable} <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <DatePicker
                          label="Pick Up Date"
                          disablePast={true}
                          onChange={(event) =>
                            handleInputChange(event, input.name)
                          }
                        />
                      </Box>
                    </LocalizationProvider>
                  );
                } else if (input?.type === "select") {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        minWidth: "150px",
                        maxWidth: "150px",
                        marginTop: "20px",
                      }}
                    >
                      <Typography sx={{ ...FeeBoxText, textAlign: "left" }}>
                        {input?.lable} <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          height: "40px",
                          border: "1px solid #E8E8E8",
                          borderRadius: "10px",
                          mt: 1,
                          px: 1,
                        }}
                      >
                        <Select
                          required
                          placeholder="10"
                          disableUnderline
                          inputProps={{ min: 0 }}
                          sx={{
                            ...InputSx,
                            ".MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                          }}
                          key={index}
                          name={input?.name}
                          size={input?.size}
                          type={input?.type}
                          value={PackgingDataObject[input?.name]}
                          onChange={(event) =>
                            handleInputChange(event, input.name)
                          }
                        >
                          {GSTData?.map((gst, idx) => {
                            return (
                              <MenuItem value={gst?.GST}>{gst?.GST}</MenuItem>
                            );
                          })}
                        </Select>
                      </Box>
                    </Box>
                  );
                }

                return (
                  <Box
                    sx={{
                      width: "100%",
                      minWidth: "150px",
                      maxWidth: "150px",
                      marginTop: "20px",
                    }}
                  >
                    <Typography sx={{ ...FeeBoxText, textAlign: "left" }}>
                      {input?.lable} <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        height: "40px",
                        border: "1px solid #E8E8E8",
                        borderRadius: "10px",
                        mt: 0.7,
                        px: 1,
                      }}
                    >
                      <Input
                        required
                        placeholder="10"
                        disableUnderline
                        onWheel={numberInputOnWheelPreventChange}
                        inputProps={{
                          min: input?.name === "packagingweight" ? 1 : 0,
                        }}
                        sx={InputSx}
                        key={index}
                        name={input.name}
                        size={input.size}
                        type={input.type}
                        value={PackgingDataObject[input.name]}
                        onChange={(event) =>
                          handleInputChange(event, input.name)
                        }
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <Box
              sx={{
                width: "100%",
                mx: "auto",
              }}
            >
              <Box sx={{ py: 0, mt: 5 }}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#C64091",
                  }}
                >
                  Transportation Details
                </Typography>
              </Box>
              <FormControl>
                <RadioGroup
                  sx={{
                    width: "100%",
                    minWidth: "600px",
                    display: {
                      xl: "flex",
                      lg: "flex",
                      md: "flex",
                      sm: "grid",
                      xs: "grid",
                    },
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    bgcolor: "transparent",
                    alignItems: "center",
                    alignContent: "center",
                    flexDirection: "row",
                    mt: 2,
                    gap: "20px",
                  }}
                  defaultValue={true}
                  onChange={(e) => setIsTransportation(e.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        sx={{
                          color: "#445FD2",
                          "& .MuiSvgIcon-root": {
                            fontSize: 22,
                          },
                        }}
                      />
                    }
                    label={
                      <StyledLabel>Add your transportation costs </StyledLabel>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        sx={{
                          color: "#445FD2",
                          "& .MuiSvgIcon-root": {
                            fontSize: 22,
                          },
                        }}
                      />
                    }
                    label={
                      <StyledLabel>We do not offer transportation</StyledLabel>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {/* ) : null} */}

            {isTransportation === "true" ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    alignItems: "center",
                    gap: "5%",
                    flexWrap: "wrap",
                    mt: "20px",
                  }}
                >
                  {InputArrayTwo?.map((input, index) => {
                    if (input?.type === "number") {
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            minWidth: "150px",
                            maxWidth: "150px",
                            marginTop: "20px",
                          }}
                        >
                          <Typography sx={{ ...FeeBoxText, textAlign: "left" }}>
                            {input?.lable}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              height: "40px",
                              border: "1px solid #E8E8E8",
                              borderRadius: "10px",
                              mt: 1,
                              px: 1,
                            }}
                          >
                            <Input
                              required
                              placeholder="10"
                              disableUnderline
                              inputProps={{ min: 0 }}
                              sx={InputSx}
                              key={index}
                              name={input.name}
                              size={input.size}
                              type={input.type}
                              value={PackgingDataObject[input.name]}
                              onChange={(event) =>
                                handleInputChangeTransportation(
                                  event,
                                  input.name
                                )
                              }
                            />
                          </Box>
                        </Box>
                      );
                    } else if (input?.type === "select") {
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            minWidth: "150px",
                            maxWidth: "150px",
                            marginTop: "20px",
                          }}
                        >
                          <Typography sx={{ ...FeeBoxText, textAlign: "left" }}>
                            {input?.lable}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              height: "40px",
                              border: "1px solid #E8E8E8",
                              borderRadius: "10px",
                              mt: 1,
                              px: 1,
                            }}
                          >
                            <Select
                              required
                              placeholder="10"
                              disableUnderline
                              inputProps={{ min: 0 }}
                              sx={{
                                ...InputSx,
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                              }}
                              key={index}
                              name={input.name}
                              size={input.size}
                              type={input.type}
                              value={PackgingDataObject[input.name]}
                              onChange={(event) =>
                                handleInputChangeTransportation(
                                  event,
                                  input.name
                                )
                              }
                            >
                              {GSTData?.map((gst, idx) => {
                                return (
                                  <MenuItem value={gst?.GST}>
                                    {gst?.GST}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Box>
                        </Box>
                      );
                    }
                  })}
                </Box>
                <Box sx={{ py: 1, marginTop: "40px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#505050",
                    }}
                  >
                    Insurance Fees
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "10px",
                      lineHeight: "15px",
                      color: "rgba(133, 133, 133, 0.67)",
                    }}
                  >
                    If the goods are insured mention the cost of insurance.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    alignItems: "center",
                    gap: "5%",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      minWidth: "200px",
                      maxWidth: "200px",
                      marginTop: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        ...FeeBoxText,
                        textAlign: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Cost of Insurance (INR)
                      {/* (Including GST) */}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        height: "40px",
                        border: "1px solid #E8E8E8",
                        borderRadius: "10px",
                        mt: 1,
                        px: 1,
                      }}
                    >
                      <Input
                        required
                        placeholder="10"
                        disableUnderline
                        inputProps={{ min: 0 }}
                        sx={InputSx}
                        onWheel={numberInputOnWheelPreventChange}
                        name="insurance"
                        size="full"
                        type="number"
                        value={InsuranceData}
                        onChange={(event) =>
                          setInsuranceData(event?.target?.value)
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      minWidth: "150px",
                      maxWidth: "150px",
                      marginTop: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        ...FeeBoxText,
                        textAlign: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      GST of Cost of Insurance (INR)
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        height: "40px",
                        border: "1px solid #E8E8E8",
                        borderRadius: "10px",
                        mt: 1,
                        px: 1,
                      }}
                    >
                      <Select
                        required
                        placeholder="10"
                        disableUnderline
                        inputProps={{ min: 0 }}
                        sx={{
                          ...InputSx,
                          ".MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                        }}
                        name={"gstofinsurance"}
                        size={"full"}
                        type={"select"}
                        value={InsuranceGST}
                        onChange={(event) =>
                          setInsuranceGST(event?.target?.value)
                        }
                      >
                        {GSTData?.map((gst, idx) => {
                          return (
                            <MenuItem value={gst?.GST}>{gst?.GST}</MenuItem>
                          );
                        })}
                      </Select>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : null}

            <Box
              sx={{
                mt: "50px",
                mx: "auto",
                maxWidth: "600px",
              }}
            >
              {openpartOrder ? (
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "28px",
                          color: "#6B7A99",
                        }}
                      >
                        Please update your available quantity here
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {OrderSummarydata?.ProductData?.map((row) => {
                      return (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              flexDirection: "row",
                              mt: 1,
                              p: 1,
                            }}
                          >
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                display: "flex",
                                flexDirection: "row",
                                borderRadius: "14px",
                                width: "100%",
                              }}
                            >
                              <Typography
                                sx={{ ...dialogtexthead, width: "70%" }}
                              >
                                Product Name
                              </Typography>
                              <Typography
                                sx={{
                                  ...dialogtexthead,
                                  width: "30%",
                                  textAlign: "end",
                                }}
                              >
                                Quantity
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              flexDirection: "row",
                              gap: "10px",
                              overflow: "none",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                background: "#FFFFFF",
                                border: "0.73322px solid #EDEFF2",
                                borderRadius: "14.5px",
                                width: "300px",
                                height: "50px",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...dialogtext,
                                  ml: 2,
                                  maxWidth: "300px",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {row?.ProductName}
                              </Typography>
                            </Box>
                            <TextField
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    border: "0.711956px solid #E6E9EE",
                                    borderRadius: "8.5px",
                                    textAlign: "center",
                                  },
                                },
                                width: "100px",
                                textAlign: "center",
                              }}
                              defaultValue={row?.ProductQuantity}
                              onChange={(e) => {
                                setQuantity({
                                  ProductId: row?.ProductId,
                                  ProductQty: e.target.value,
                                  ProductsVariantionId:
                                    row?.ProductsVariantionId,
                                });
                              }}
                            />

                            <Button
                              onClick={() => {
                                setIsPartialState(true);
                                setQuantityArr([...QuantityArr, quantity]);
                              }}
                            >
                              Confirm
                            </Button>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                  {/* <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ ...reqbtn, width: "130px" }}
                      onClick={() => {
                        setIsPartialState(true);
                        setQuantityArr([...QuantityArr, quantity]);
                      }}
                    >
                      Update Quantity
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        ...reqbtn,
                        fontWeight: 700,
                        background: "none",
                        border: "0.7px solid #EBEDEE",
                        color: "#2261A2",
                      }}
                      onClick={() => setOpenPartOrder(false)}
                    >
                      Cancel
                    </Button>
                  </Box> */}
                </div>
              ) : null}
            </Box>
            <Box
              sx={{
                width: "100%",
                mt: 3,
                pb: 3,
              }}
            >
              <Box
                sx={{
                  maxWidth: "600px",
                  mx: "auto",
                  gap: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  height: "100%",
                }}
              >
                <Box
                  sx={ButtonStyleForAcceptAndReject}
                  onClick={() => {
                    UpdateOrRejectThePurchaseOrder("Accepted");
                    setNotificationOn("Accepted");
                  }}
                >
                  Confirm
                </Box>

                <Box
                  sx={{
                    ...ButtonStyleForAcceptAndReject,
                    bgcolor: "#fff",
                    border: "1px solid #c64091",
                    color: "#c64091",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Box>
                <Button
                  sx={{
                    ...partorder,
                    bgcolor: "#ffffff",
                    border: "1px solid #f71702",
                    color: "#f71702",
                  }}
                  variant="outlined"
                  onClick={() => setOpenPartOrder(true)}
                >
                  Part Order
                </Button>
              </Box>
            </Box>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default POAcceptModal;

const InputSx = {
  width: "95%",
  height: "35px",
  border: "none",
  m: "auto",
  fontSize: "14px",
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
};

const FeeBoxText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const dialogtexthead = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#6B7A99",
};

const dialogtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "22px",
  color: "#6B7A99",
};

const reqbtn = {
  width: "114px",
  height: "42px",
  boxShadow: "none",
  background: "#2261A2",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  textTransform: "none",
};

const ButtonStyleForAcceptAndReject = {
  width: "100%",
  height: "35px",
  maxWidth: "180px",
  borderRadius: "6px",
  bgcolor: "#c64091",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
  "&:hover": {
    background: "#445FD2",
  },
};

const partorder = {
  width: "100%",
  height: "35px",
  maxWidth: "180px",
  borderRadius: "6px",
  bgcolor: "#ffffff",
  textTransform: "none",
  color: "#2261A2",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  color: "#2261A2",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "674px",
  height: "441px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const ButtonGroupstyle = {
  width: "100%",
  border: "none",
};

const RejectReasonBtnStyle = {
  width: "100%",
  mx: "auto",
  textTransform: "none",
  height: "40px",
  borderRadius: "6px",
  border: "1px solid #E4E7EC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const TextAndCheckBoxGroupStyle = {
  width: "100%",
  px: "10px",
  cursor: "pointer",
};

const ReasonTextStyle = {
  fontFamily: "poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#6B7A99",
};

const TextAreaStyle = {
  width: "100%",
  mx: "auto",
  background: "#ECEFF1",
  color: "#C7C7CC",
  border: "1px solid lighgray",
  height: "100px",
  "& fieldset": { border: "none" },
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  my: 1,
};

const InputPropsStyle = {
  // disableUnderline: true,
  sx: {
    background: "#ECEFF1",
    fontFamily: "Poppins",
    color: "#445FD2",
    borderRadius: "10px",
    fontSize: "14px",
    width: "100%",
  },
};
