import { a } from 'react-spring';

export const smallHeadText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '15px', lg: '15px', md: '15px', sm: '13px', xs: '13px' },
  fontWeight: 300,
  color: 'rgba(35, 36, 42, 1)',
  letterSpacing: '0.11em',
  textTransform: 'uppercase',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
};

export const headText = {
  mt: 1,
  fontFamily: 'Poppins',
  fontSize: { xl: '26px', lg: '26px', md: '18px', sm: '16px', xs: '16px' },
  fontWeight: 500,
  letterSpacing: '0.01em',
  color: 'rgba(35, 36, 42, 1)',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
  width: { xl: '30%', lg: '30%', md: '100%', sm: '100%', xs: '100%' },
  lineHeight: { xl: '32px', lg: '32px', md: '32px', sm: '26px', xs: '26px' },
};
export const headMainText = {
  mt: 1,
  fontFamily: 'Poppins',
  fontSize: { xl: '26px', lg: '26px', md: '18px', sm: '16px', xs: '16px' },
  fontWeight: 500,
  letterSpacing: '0.01em',
  color: 'rgba(35, 36, 42, 1)',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
  width: {
    xl: '90%',
    lg: '90%',
    md: '100%',
    sm: '100%',
    xs: '100%',
  },
  mx: 'auto',
  lineHeight: { xl: '32px', lg: '32px', md: '32px', sm: '26px', xs: '26px' },
};

export const categoryText = {
  fontFamily: 'poppins',
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '12px', xs: '12px' },
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 1)',
  py: 0.8,
};

export const insideCategoryBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 2,
  background: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 10px 20px rgba(220, 220, 220, 0.5)',
  borderRadius: '10px',
  width: 'auto',
  height: 'auto',
  px: 2,
  mt: 1,
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '1px 1px 30.6px 1px rgba(47, 63, 87, 0.08)',
  },
};

export const ExlporeBtn = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '15px',
  background: 'rgba(108, 108, 162, 1)',
  p: 1,
  fontFamily: 'Poppins',
  fontSize: {
    xl: '13px',
    lg: '13px',
    md: '13px',
    sm: '12px',
    xs: '12px',
  },
  cursor: 'pointer',
  fontWeight: 600,
  color: 'rgba(255, 255, 255, 1)',
  minWidth: '200px',
  mb: { xl: 0, lg: 0, md: 2, sm: 2, xs: 2 },
};

export const sellerBox = {
  width: {
    xl: '65%',
    lg: '65%',
    md: '100%',
    sm: '100%',
    xs: '100%',
  },
  display: 'flex',
  justifyContent: {
    xl: 'flex-start',
    lg: 'flex-start',
    md: 'center',
    sm: 'center',
    xs: 'center',
  },
  flexDirection: {
    xl: 'start',
    lg: 'start',
    md: 'center',
    sm: 'center',
    xs: 'center',
  },
  flexWrap: 'wrap',
  gap: 2,
  // ml: 'auto',
};

export const headTextColor = {
  mt: 1,
  fontFamily: 'Poppins',
  fontSize: { xl: '26px', lg: '26px', md: '18px', sm: '16px', xs: '16px' },
  fontWeight: 500,
  letterSpacing: '0.01em',
  color: 'rgba(35, 36, 42, 1)',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
  width: '100%',
  lineHeight: { xl: '32px', lg: '32px', md: '32px', sm: '26px', xs: '26px' },
};

export const subTrustText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '20px', lg: '20px', md: '18px', sm: '12px', xs: '12px' },
  fontWeight: 400,
  color: 'rgba(35, 36, 42, 1)',
  textAlign: 'center',
};

export const subCompanyText = {
  fontFamily: 'Inter',
  fontSize: { xl: '14px', lg: '14px', md: '12px', sm: '10px', xs: '10px' },
  fontWeight: 400,
  color: 'rgba(73, 75, 122, 1)',
  textAlign: 'center',
};

export const companyBox = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  flexDirection: 'column',
  justifyContent: 'space-around',
  borderRadius: '10px',
  border: '1px solid rgba(225, 225, 225, 1)',
  maxWidth: { xl: '300px', lg: '300px', md: '300px', sm: '230px', xs: '230px' },
  height: { xl: '280px', lg: '280px', md: '280px', sm: '220px', xs: '220px' },
  p: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
};

export const boxColorText = {
  fontFamily: 'Poppins',
  textAlign: 'left',
  fontSize: { xl: '12px', lg: '12px', md: '12px', sm: '10px', xs: '10px' },
  fontWeight: 400,
  color: 'rgba(180, 78, 161, 1)',
  width: '100%',
};

export const boxColorText2 = {
  fontFamily: 'Poppins',
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '12px', xs: '12px' },
  fontWeight: 700,
  color: 'rgba(108, 108, 162, 1)',
  width: '100%',
  textAlign: 'left',
  whiteSpace: 'wrap',
};

export const boxColorText3 = {
  fontFamily: 'Poppins',
  fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '12px', xs: '12px' },
  fontWeight: 400,
  color: 'rgba(71, 79, 98, 1)',
  width: '100%',
  textAlign: 'left',
};

export const newsblogBox = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  flexDirection: 'column',
  justifyContent: 'space-around',
  borderRadius: '20px',
  border: '2px solid rgba(247, 247, 247, 1)',
  boxShadow: '0px 10px 10px 5px rgba(162, 182, 212, 0.05)',
  minWidth: { xl: '300px', lg: '300px', md: '300px', sm: '230px', xs: '230px' },
  maxWidth: { xl: '300px', lg: '300px', md: '300px', sm: '230px', xs: '230px' },
  height: { xl: '300px', lg: '300px', md: '300px', sm: '250px', xs: '250px' },
  p: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
  mr: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
};

export const leagalBtn = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
  background: 'rgba(130, 114, 181, 1)',
  borderRadius: '10px',
  fontFamily: 'Poppins',
  fontSize: {
    xl: '12px',
    lg: '12px',
    md: '12px',
    sm: '10px',
    xs: '10px',
  },
  fontWeight: 700,
  color: 'rgba(255, 255, 255, 1)',
  minWidth: '120px',
  maxWidth: '120px',
  mt: 1,
};

export const leagalSubText = {
  fontFamily: 'poppins',
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '12px', xs: '12px' },
  fontWeight: 400,
  color: 'rgba(66, 82, 107, 1)',
  width: { xl: '70%', lg: '70%', md: '70%', sm: '95%', xs: '95%' },
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
};

export const accordinStylesBox = {
  maxHeight: '400px',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: { xl: '80%', lg: '80%', md: '90%', sm: '100%', xs: '100%' },
  mx: 'auto',
  background: '#fff',
  boxShadow: ' 0px 10px 20px rgba(6, 28, 61, 0.08)',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  py: 1,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const faqMainPage = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'column',
    sm: 'column',
    xs: 'column',
  },
  p: 2,
  width: '80%',
  mx: 'auto',
  gap: '20px',
};

export const faqQuestionText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '12px', xs: '12px' },
  fontWeight: 500,
  // color: 'rgba(6, 28, 61, 1)',
};

export const faqAnsweText = {
  color: 'rgba(6, 28, 61, 1)',
  fontFamily: 'Poppins',
  fontSize: {
    xl: '12px',
    lg: '12px',
    md: '12px',
    sm: '10px',
    xs: '10px',
  },
  fontWeight: 400,
  width: '100%',
};

export const smallChooseText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '12px', xs: '12px' },
  fontWeight: 400,
  textAlign: 'center',
  color: 'rgba(35, 36, 42, 1)',
};

export const slidecolorText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '17px', lg: '17px', md: '17px', sm: '16px', xs: '16px' },
  fontWeight: 600,
  color: 'rgba(108, 108, 162, 1)',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
};

export const slideSubText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '10px', lg: '10px', md: '10px', sm: '8px', xs: '8px' },
  fontWeight: 400,
  color: 'rgba(83, 83, 83, 1)',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
};

export const slideSubText2 = {
  fontFamily: 'Poppins',
  fontSize: { xl: '10px', lg: '10px', md: '10px', sm: '10px', xs: '10px' },
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 1)',
  textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' },
};

export const cardTextBox = {
  textAlign: 'left',
  padding: '0 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: {
    xl: 'flex-start',
    lg: 'flex-start',
    md: 'flex-start',
    sm: 'center',
    xs: 'center',
  },
  gap: '10px',
};

export const cardTextBoxAbove = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: {
    xl: 'row',
    lg: 'row',
    md: 'row',
    sm: 'column-reverse',
    xs: 'column-reverse',
  },
  mt: 8,
  width: '95%',
  mx: 'auto',
};
