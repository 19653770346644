import BreadCrumbHeader from "../../../../components/Header/BreadCrumbHeader.jsx";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  FormControl,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import DotLoader from "../../../../components/LoadingButton/DotLoader.js";
import GlobalToast from "../../../../components/Toasts/GlobalToast.jsx";

const useStyles = makeStyles({
  tableRow: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
});

const MediaOffline = [
  {
    key: 1,
    value: "Offline Media Output File - Newspaper/Magazines",
    name: "News Papers / Magazines",
  },
  {
    key: 2,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Gantry Gate",
  },
  {
    key: 3,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Hoardings",
  },
  {
    key: 4,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Skywalk",
  },
  {
    key: 5,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Wall Branding",
  },
  {
    key: 6,
    value: "Offline Media Output File - Others",
    name: "Activation",
  },
  {
    key: 7,
    value: "Offline Media Output File - Others",
    name: "Ambient Media",
  },
  {
    key: 8,
    value: "Offline Media Output File - Others",
    name: "Banners",
  },
  {
    key: 9,
    value: "Offline Media Output File - Others",
    name: "Boarding Pass",
  },
  {
    key: 10,
    value: "Offline Media Output File - Others",
    name: "Bus Handles",
  },
  {
    key: 11,
    value: "Offline Media Output File - Others",
    name: "Bus Shelters",
  },
  {
    key: 12,
    value: "Offline Media Output File - Others",
    name: "Dangler",
  },
  {
    key: 13,
    value: "Offline Media Output File - Others",
    name: "Door Handles",
  },
  {
    key: 14,
    value: "Offline Media Output File - Others",
    name: "Drop Down Banners",
  },
  {
    key: 15,
    value: "Offline Media Output File - Others",
    name: "Elevator Branding",
  },
  {
    key: 16,
    value: "Offline Media Output File - Others",
    name: "Escalator Branding",
  },
  {
    key: 17,
    value: "Offline Media Output File - Others",
    name: "Flight Wrap",
  },
  {
    key: 18,
    value: "Offline Media Output File - Others",
    name: "Kiosk",
  },
  {
    key: 19,
    value: "Offline Media Output File - Others",
    name: "Others",
  },
  {
    key: 20,
    value: "Offline Media Output File - Others",
    name: "Pole Kiosk",
  },
  {
    key: 21,
    value: "Offline Media Output File - Others",
    name: "Poster",
  },
  {
    key: 22,
    value: "Offline Media Output File - Others",
    name: "Posters",
  },
  {
    key: 23,
    value: "Offline Media Output File - Others",
    name: "Promoter Activity",
  },
  {
    key: 24,
    value: "Offline Media Output File - Others",
    name: "Standee",
  },
  {
    key: 25,
    value: "Offline Media Output File - Others",
    name: "Tent Card",
  },
  {
    key: 26,
    value: "Offline Media Output File - Others",
    name: "Ticket Branding",
  },
  {
    key: 27,
    value: "Offline Media Output File - Others",
    name: "Train Wrap",
  },
];

const MediaOfflineBulkUploadPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [upload, setUpload] = useState(null);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offlineCategory, setOfflineCategory] = useState("");
  const [storeCompanyType, setStoreCompanyType] = useState("");
  const [storeStoredFiles, setStoreStoredFiles] = useState([]);

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const myForm = new FormData();
    myForm.append("file", file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      setUpload(data);
    };

    reader.onerror = (err) => {};
    reader?.readAsArrayBuffer(file);
    uploadExcelFile(file);
  };

  const uploadExcelFile = async (myForm) => {
    if (offlineCategory === "" || !offlineCategory) {
      alert("Please select an online category");
      return;
    }
    try {
      setLoading(true);

      const selectedCategory = MediaOffline.find(
        (item) => item.name === offlineCategory
      );

      const formData = new FormData();
      formData.append("file", myForm);
      formData.append("data", selectedCategory?.value);
      const { data } = await axios.post(
        "publiq_bulk_upload/bulk_upload",
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      if (data?.success === true) {
        toast.success("File uploaded successfully", {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setData(data?.success);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.name, {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Network Error:", error.message);
      }
    }
  };

  const findAllWebHookReponsesAndFiles = async () => {
    try {
      await axios
        .get(`publiq_bulk_upload/findAll_BulkUploadResponseAnd_NonStoreFiles`)
        .then((response) => {
          setStoreStoredFiles(response.data?.data);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const handleMediaOfflineCategoryChange = (event) => {
    const name = event.target.value;

    setOfflineCategory(name);
  };

  const confirmedUploadTheFile = async (Unique_id) => {
    if (window.confirm("Are you sure you want to add products?")) {
      try {
        await axios
          .post(
            `publiq_bulk_upload/upload_bulkUpload_files_mediaoffline`,
            {
              data: Unique_id,
            },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            GlobalToast(response.data.message, "success");
          })
          .catch((error) => {
            GlobalToast(error.response.data.message, "error");
          });
      } catch (error) {
        GlobalToast(error.response.data.message, "error");
        console.error("Error uploading the file:", error);
      }
    }
  };

  useEffect(() => {
    const companyType = localStorage.getItem("companyType");
    setStoreCompanyType(companyType);
    findAllWebHookReponsesAndFiles();
  }, []);

  return (
    <>
      <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />

      <Paper
        sx={{
          width: "100%",
          height: "70vh",
          position: "relative",
          display: "flex",
        }}
        elevation={0}
      >
        <Box
          sx={{
            width: "40%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data ? (
            <Container
              sx={{
                display: "grid",
                width: "100%",
                maxWidth: "100%",
                textAlign: "center",
                gap: "15px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#444444",
                  fontFamily: "Poppins",
                  marginTop: "24px",
                  fontFamily: "Poppins",
                  display: "grid",
                  gap: "15px",
                }}
              >
                Please wait for some times while we go through your uploaded
                file. <DotLoader />
              </Typography>
            </Container>
          ) : (
            <Container
              sx={{
                display: "grid",
                width: "700px",
                maxWidth: "700px",
                textAlign: "center",
                gap: "15px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                Media Offline
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "#444444",
                        fontFamily: "Poppins",
                      }}
                    >
                      Select Category
                    </Typography>
                    <FormControl
                      fullWidth
                      margin="normal"
                      sx={{ maxWidth: "400px" }}
                    >
                      <select
                        labelId="category-label"
                        value={offlineCategory}
                        label="Category"
                        defaultValue={""}
                        onChange={handleMediaOfflineCategoryChange}
                        style={{
                          border: "1px solid #c64091",
                          height: "40px",
                          width: "100%",
                          padding: "10px",
                          borderRadius: "5px",
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          lineHeight: "1.5",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <option
                          value={""}
                          disabled={true}
                          style={{
                            padding: "10px",
                            height: "35px",
                            width: "100%",
                          }}
                        >
                          <Typography variant="h6">
                            {`<-- Select -->`}
                          </Typography>
                        </option>
                        {MediaOffline?.map((res, idx) => {
                          return (
                            <option
                              key={idx}
                              value={res?.name}
                              style={{
                                padding: "10px",
                                height: "35px",
                                width: "100%",
                              }}
                            >
                              <Typography variant="h6">{res?.name}</Typography>
                            </option>
                          );
                        })}
                      </select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "#444444",
                        fontFamily: "Poppins",
                      }}
                    >
                      Choose you Excel File{" "}
                    </Typography>
                    <input
                      type="file"
                      ref={inputFile}
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="contained"
                      onClick={onButtonClick}
                      sx={{
                        height: "40px",
                        fontSize: "14px",
                        textTransform: "none",
                        width: "400px",
                        background: "#c64091",
                        "&:hover": {
                          background: "#fff",
                          color: "#c64091",
                          cursor: "pointer",
                          border: "1px solid #c64091",
                        },
                      }}
                    >
                      Add file <FileUploadIcon fontSize="large" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Container>
          )}
        </Box>
        <Box
          sx={{
            borderLeft: "1px solid #c64091",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "60%",
          }}
        >
          <Box
            sx={{
              mx: "auto",
              width: "80%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 500,
              }}
            >
              Recent Uploaded Media Files
            </Typography>
            <Box
              sx={{
                maxHeight: "300px",
                overflowY: "scroll",
                my: 2,
              }}
            >
              {storeStoredFiles?.map((res, idx) => {
                // if (res?.data?.Link && res?.data?.Link.includes("Offline")) {
                return (
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "55%",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "12px",
                          wordBreak: "break-all",
                        }}
                      >
                        {res?.data?.Link}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "40%",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          res?.data?.status_code === 202 ||
                          res?.data?.status_code === 400
                            ? true
                            : false
                        }
                        onClick={() =>
                          confirmedUploadTheFile(res?.data?.Unique_id)
                        }
                        sx={{
                          height: "40px",
                          fontSize: "14px",
                          textTransform: "none",
                          width: "150px",
                          background: "#c64091",
                          "&:hover": {
                            background: "#fff",
                            color: "#c64091",
                            cursor: "pointer",
                            border: "1px solid #c64091",
                          },
                        }}
                      >
                        Upload Products
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href={res?.data?.Link}
                        sx={{
                          height: "40px",
                          fontSize: "14px",
                          textTransform: "none",
                          width: "150px",
                          background: "#fff",
                          color: "#c64091",
                          border: "1px solid #c64091",
                          "&:hover": {
                            background: "#c64091",
                            color: "#fff",
                            cursor: "pointer",
                            border: "1px solid #c64091",
                          },
                        }}
                      >
                        Download File
                      </Button>
                    </Box>
                  </Box>
                );
                // }
              })}
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default MediaOfflineBulkUploadPage;
